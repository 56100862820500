<template>
  <div class="entry-actions">
    <div @click="addChild()">Ajouter&nbsp;un&nbsp;enfant</div>
    <div @click="updateEntry()">Modifier</div>
    <div @click="changeParent()">Changer de parent</div>
    <div @click="duplicate()">Dupliquer</div>
    <div class="separator"></div>
    <div v-if="!entry.deletedAt" @click="deleteEntry()">Supprimer</div>
    <div v-else @click="restoreEntry()">Restaurer</div>
    <div @click="hardDeleteEntry()">Détruire</div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import ApiService from "@/services/apiService";
  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  import { useUI } from "@/state/uiStore";

  @Options({
    name: "EntryActions",
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
    },
    emits: ["refresh", "closeParent"],
  })
  export default class EntryActions extends Vue {
    entry!: Entry;
    ui: any = null;

    async mounted() {
      this.ui = useUI();
    }

    addChild() {
      this.ui.main.set("entryEditor", {
        data: {
          title: "Ajout d'un enfant",
          entry: this.entry,
          labels: this.entry.labels,
          mode: "AddChild",
        },
        addCntnr: false,
      });
    }

    updateEntry() {
      this.ui.main.set("entryEditor", {
        data: {
          title: "MAJ d'une entrée",
          entry: this.entry,
          labels: this.entry.labels,
          mode: "Update",
        },
        addCntnr: false,
      });
    }

    changeParent() {
      this.ui.main.set("entryMigrator", {
        data: {
          entry: this.entry,
        },
        addCntnr: false,
      });
    }

    duplicate() {
      this.$emit("closeParent");
      this.ui.confirm.show(() => {
        this.ui.spinner.show();
        ApiService.post("entries", {
          name: this.entry.name + " COPIE",
          content: this.entry.content,
          labels: this.entry.labels,
          parent: this.entry.parent,
          xtra: this.entry.xtra,
        })
          .then((response: any) => {
            this.$success(response);
            this.ui.main.set("entryDetails", {
              data: response.data.data._id,
              addCntnr: true,
            });
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      });
    }

    deleteEntry() {
      this.ui.spinner.show();
      ApiService.delete("entries/" + this.entry._id)
        .then((response) => {
          this.$success(response);
          this.$emit("refresh", response.data.data);
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    hardDeleteEntry() {
      this.$emit("closeParent");
      this.ui.confirm.show(() => {
        this.ui.spinner.show();
        ApiService.delete("entries/hard/" + this.entry._id)
          .then((response: any) => {
            this.$warning(response);
            this.ui.main.close();
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      });
    }

    restoreEntry() {
      this.ui.spinner.show();
      ApiService.put("entries/restore/" + this.entry._id)
        .then((response: any) => {
          this.$emit("refresh", response.data.data);
          this.$success(response);
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }
  }
</script>

<style scoped lang="scss">
  .entry-actions {
    @apply whitespace-nowrap;
    > div {
      @apply p-3;
      &.separator {
        @apply p-0 border-t mx-3 border-gray-600;
      }
    }
  }
</style>
