import Entry from "@/interfaces/entry.interface";

import { useLabels } from "@/state/labelsStore";

class TypesService {
    private _labelsStore: any = null;

    vals: any = {
        Default: "default",
        Event: "event",
        Task: "task",
        Todolist : 'todolist',
    };

    strs: any = {
        default: "Défaut",
        event: "Événements",
        task: "Tâches",
        todolist : 'Listes de tâches',
    }

    get availableTypes(): any[] {
        let output: any = {};
        for (let item in this.strs) {
            output[item] = this.strs[item];
        }
        return output;
    }

    getEntryTypes(entry: Entry): string[] {
        return this.extractLabelTypes(entry.labels);
    }

    extractLabelTypes(labelIds: string[]): string[] {
        // .map retrieve type from label id
        // new set remove duplicate
        return [...new Set(labelIds.map((lId: string) => this.labelsStore.state.i[lId].type))];
    }

    get labelsStore() {
        if (!this._labelsStore) {
            this._labelsStore = useLabels();
        }
        return this._labelsStore;
    }
}
export const typesService = new TypesService();