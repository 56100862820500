
  import { Options, Vue } from "vue-class-component";

  import { typesService } from "@/services/typesService";

  import EventActions from "@/components/Types/EventActions.vue";
  import TaskActions from "@/components/Types/TaskActions.vue";
  import TodolistActions from "@/components/Types/TodolistActions.vue";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "XtraActions",
    components: { EventActions, TaskActions, TodolistActions },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
      mode: String,
    },
    emits: ["refresh"],
  })
  export default class XtraActions extends Vue {
    entry!: Entry;
    mode: string = "icon"; // text

    types: string[] = [];

    async mounted() {
      this.types = typesService.getEntryTypes(this.entry);
    }

    get isEvent() {
      return this.types.indexOf(typesService.vals.Event) !== -1;
    }

    get isTask() {
      return this.types.indexOf(typesService.vals.Task) !== -1;
    }

    get isTodolist(): boolean {
      return this.types.indexOf(typesService.vals.Todolist) !== -1;
    }

    refresh(entry: Entry) {
      this.$emit("refresh", entry);
    }
  }
