<template>
  <div class="xtra-editor" v-if="isTask || isEvent || isTodolist">
    <EventEditor v-if="isEvent" :xtra="xtra" />
    <TaskEditor v-if="isTask" :xtra="xtra" />
    <TodolistEditor v-if="isTodolist" :xtra="xtra" />
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import EventEditor from "@/components/Types/EventEditor.vue";
  import TaskEditor from "@/components/Types/TaskEditor.vue";
  import TodolistEditor from "@/components/Types/TodolistEditor.vue";

  import { typesService } from "@/services/typesService";
  import { useLabels } from "@/state/labelsStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "XtraEditor",
    components: { EventEditor, TaskEditor, TodolistEditor },
    props: {
      xtra: {
        required: true,
      },
      labelIds: {
        required: true,
      },
    },
  })
  export default class XtraEditor extends Vue {
    xtra!: any;
    labelIds!: string[];
    labels: Label[] = [];

    labelsStore: any = null;

    async mounted() {
      this.labelsStore = useLabels();
    }

    get types(): string[] {
      return typesService.extractLabelTypes(this.labelIds);
    }

    get isEvent(): boolean {
      return this.types.indexOf(typesService.vals.Event) !== -1;
    }

    get isTask(): boolean {
      return this.types.indexOf(typesService.vals.Task) !== -1;
    }

    get isTodolist(): boolean {
      return this.types.indexOf(typesService.vals.Todolist) !== -1;
    }
  }
</script>

<style scoped></style>
