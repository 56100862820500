
  import { watchEffect } from "vue";
  import { Options, Vue } from "vue-class-component";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";
  import DatePicker from "v-calendar";

  @Options({
    name: "DatetimePicker",
    components: { CloseCircleIcon, DatePicker },
    props: {
      modelValue: {
        required: true,
      },
      placeholder: String,
    },
    emits: ["update:modelValue"],
  })
  export default class DatetimePicker extends Vue {
    modelValue!: any;
    model: any = null;
    placeholder!: string;

    attributes: any = [
      {
        dot: true,
        dates: [new Date()],
      },
    ];

    async created() {
      this.model = this.modelValue ? new Date(this.modelValue) : null;
      watchEffect(() => {
        if (this.model) {
          this.$emit("update:modelValue", this.model);
        }
      });
    }

    clean() {
      this.model = null;
      this.$emit("update:modelValue", this.model);
    }
  }
