<template>
  <div class="nestor">
    <div class="main nestor-scrollbar">
      <EntriesAgenda
        v-if="ui.state.main.mode === 'entriesAgenda'"
        :opt="ui.state.main.data"
      />
      <EntriesSearch
        v-if="ui.state.main.mode === 'entriesSearch'"
        :opt="ui.state.main.data"
      />
      <EntryDetails
        v-if="ui.state.main.mode === 'entryDetails'"
        :entry-id="ui.state.main.data"
      />
      <EntryEditor
        v-if="ui.state.main.mode === 'entryEditor'"
        :opt="ui.state.main.data"
      />
      <EntryMigrator
        v-if="ui.state.main.mode === 'entryMigrator'"
        :opt="ui.state.main.data"
      />
      <LabelEditor
        v-if="ui.state.main.mode === 'labelEditor'"
        :opt="ui.state.main.data"
      />
      <LabelsList v-if="ui.state.main.mode === 'labelsList'" :opt="ui.state.main.data" />
    </div>
    <div class="bot">
      <Menu />
      <AddCntnr />
    </div>
    <div class="app-mask" v-if="ui.state.spinner.active">
      <Spinner />
    </div>
    <div class="app-mask" v-if="ui.state.confirm.active">
      <ConfirmPopup />
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import AddCntnr from "@/components/Layout/AddCntnr.vue";
  import ConfirmPopup from "@/components/Basis/ConfirmPopup.vue";
  import EntriesAgenda from "@/components/Entries/EntriesAgenda.vue";
  import EntriesSearch from "@/components/Entries/EntriesSearch.vue";
  import EntryDetails from "@/components/Entries/EntryDetails.vue";
  import EntryEditor from "@/components/Entries/EntryEditor.vue";
  import EntryMigrator from "@/components/Entries/EntryMigrator.vue";
  import LabelEditor from "@/components/Labels/LabelEditor.vue";
  import LabelsList from "@/components/Labels/LabelsList.vue";
  import Menu from "@/components/Layout/Menu.vue";
  import Spinner from "@/components/Basis/Spinner.vue";

  import { useUI } from "@/state/uiStore";
  import { useLabels } from "@/state/labelsStore";

  @Options({
    name: "Nestor",
    components: {
      AddCntnr,
      ConfirmPopup,
      EntriesAgenda,
      EntriesSearch,
      EntryDetails,
      EntryEditor,
      EntryMigrator,
      LabelEditor,
      LabelsList,
      Menu,
      Spinner,
    },
    props: {},
  })
  export default class Nestor extends Vue {
    mainOpt: any = null;
    ui: any = null;
    labels: any = null;

    async created() {
      this.ui = useUI();
      this.labels = useLabels();
      this.labels.fetch();
    }

    async mounted() {
      this.ui.main.set(
        "initial",
        {
          origin: null,
          data: null,
          addCntnr: true,
        },
        true
      );
    }
  }
</script>

<style scoped lang="scss">
  .nestor {
    @apply flex flex-col h-screen relative;
    .main {
      @apply flex-1 overflow-y-auto;
      padding-bottom: $icon_size_1;
    }
    .bot {
      @apply flex justify-between absolute bottom-0 right-0 left-0;
      background-color: $level_2;
    }
    .app-mask {
      @apply fixed inset-0 z-50;
      @apply bg-gray-600 bg-opacity-50;
      @apply flex justify-center items-center;
    }
  }
</style>
