
  import axios from "axios";

  import { Options, Vue } from "vue-class-component";

  import EntryBrief from "@/components/Entries/EntryBrief.vue";
  import SearchIcon from "vue-ionicons/dist/md-search.vue";
  import ApiService from "@/services/apiService";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  import { useUI } from "@/state/uiStore";

  @Options({
    name: "EntryMigrator",
    components: { EntryBrief, SearchIcon },
    props: {
      opt: {},
    },
  })
  export default class EntryMigrator extends Vue {
    opt!: any;

    entry: Entry | null = null;
    newParent: Entry | null = null;
    searchStr: string = "";
    searchResult: Entry[] | null = null;

    total: number = 0;
    cancelSource: any = null;

    ui: any = null;

    get canSave() {
      let out = false;
      if (this.entry) {
        if (this.newParent !== null) {
          if (this.entry.parent === null) {
            out = true;
          } else if (this.newParent._id !== this.entry.parent._id) {
            out = true;
          }
        }
      }
      return out;
    }

    get saveCtaClasses() {
      let out = ["save-cta"];
      if (this.canSave) {
        out.push("enabled");
      }
      return out.join(" ");
    }

    async mounted() {
      this.entry = this.opt.entry;
      this.ui = useUI();
    }

    selectEntry(sEntry: Entry) {
      this.newParent = sEntry;
    }

    onSearch(event: any) {
      const newValue = event.target.value;
      if (newValue.length > 2) {
        this.getList(newValue);
      }
    }

    getList(searchStr: string) {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.cancelSource = axios.CancelToken.source();
      this.ui.spinner.show();
      return ApiService.get(
        "entries",
        {
          skip: 0,
          limit: 15,
          order: { name: "asc" },
          params: {
            name: searchStr,
          },
        },
        this.cancelSource
      )
        .then((response) => {
          this.cancelSource = null;
          this.total = response.data.total;
          this.searchResult = response.data.items;
          this.ui.spinner.hide();
        })
        .catch((error) => {
          this.$error(error);
        });
    }

    save() {
      this.ui.spinner.show();
      if (this.entry && this.newParent) {
        ApiService.put("entries/parent/" + this.entry._id, {
          parent: this.newParent._id,
        })
          .then((response: any) => {
            this.$success(response);
            this.ui.main.close();
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      }
    }
  }
