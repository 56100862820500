<template>
  <div class="event-editor">
    <label>À faire le :</label>
    <DatetimePicker v-model="xtra.plannedAt" placeholder="À faire le" />
    <label>À faire après le :</label>
    <DatetimePicker v-model="xtra.after" placeholder="À faire après le" />
    <label>À faire avant le :</label>
    <DatetimePicker v-model="xtra.before" placeholder="À faire avant le" />
    <div v-if="xtra.completedAt">
      <label>Fait le :</label>
      <DatetimePicker v-model="xtra.completedAt" placeholder="Fait le" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import DatetimePicker from "@/components/Basis/DatetimePicker.vue";

  @Options({
    name: "EventEditor",
    components: { DatetimePicker },
    props: {
      xtra: {
        required: true,
      },
    },
  })
  export default class EventEditor extends Vue {
    xtra!: any;
  }
</script>

<style scoped lang="scss">
  .event-editor {
    label {
      @apply block px-5;
    }
  }
</style>
