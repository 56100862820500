import axios from 'axios';
import { useAuth } from "@/state/authStore";

export default abstract class ApiService {
    private static auth: any = null;

    static post(route: string, params?: any) {
        return this.requestApi('post', route, params);
    }

    static get(route: string, params?: any, cancelToken: any = null) {
        return this.requestApi('get', route, params, cancelToken);
    }

    static delete(route: string) {
        return this.requestApi('delete', route);
    }

    static put(route: string, params?: any) {
        return this.requestApi('put', route, params);
    }

    static requestApi(method: string, endpoint: string, data?: any, cancelToken: any = null) {
        let jwt = this.getAuth().state.token;
        let url = this.getApiUrl(endpoint);
        let headers = {
            Authorization: `Bearer ${jwt}`
        };

        let config: any = {
            method: method,
            url: url,
            headers: headers
        };

        switch (method) {
            case 'get':
                config.params = data || null;
                break;
            case 'post':
                config.data = data || {};
                break;
            case 'put':
                config.data = data || {};
                break;
            case 'delete':
                config.data = data || {};
                break;
        }
        if (cancelToken !== null) {
            config.cancelToken = cancelToken.token;
        }

        return axios(config);
    }

    private static getApiUrl(route: string) {
        let host = process.env.VUE_APP_API_URL;
        if (host.slice(-1) !== '/') {
            host += '/';
        }

        return host + route;
    }

    private static getAuth() {
        if (!this.auth) {
            this.auth = useAuth();
        }
        return this.auth;
    }
}