import moment from "moment";

export default abstract class DateHelper {

  static dayOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  static locale: any = {
    past: "Passées",
    today: "Aujourd'hui",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
    nextWeek: "Semaine prochaine",
    after: "Plus tard",
    unplanned: "Non planifiées"
  }

  static _today: Date | null = null;
  static get today() {
    if (!this._today) {
      this._today = new Date();
      this._today.setHours(0);
      this._today.setMinutes(0);
      this._today.setSeconds(0);
      this._today.setMilliseconds(0);
    }
    return this._today;
  }

  static _tomorrow: Date | null = null;
  static get tomorrow() {
    if (!this._tomorrow) {
      this._tomorrow = new Date(this.today.getTime());
      this._tomorrow.setDate(this.today.getDate() + 1);
    }
    return this._tomorrow;
  }


  static _nextMonday: Date | null = null;
  static get nextMonday() {
    if (!this._nextMonday) {
      this._nextMonday = new Date(this.today.getTime());
      this._nextMonday.setDate(this.today.getDate() + (1 + 7 - this.today.getDay()) % 7);
    }
    return this._nextMonday;
  }

  static _nextNextMonday: Date | null = null;
  static get nextNextMonday() {
    if (!this._nextNextMonday) {
      this._nextNextMonday = new Date(this.today.getTime());
      this._nextNextMonday.setDate(this.today.getDate() + 7 + (1 + 7 - this.today.getDay()) % 7);
    }
    return this._nextNextMonday;
  }

  static date(date: Date): string {
    let output: string = moment(new Date(date)).format("DD/MM/YYYY");
    const hours: string = moment(new Date(date)).format("HH:mm");
    if (hours !== '00:00') {
      output += ' à ' + hours;
    }
    return output;
  }

  static since(date: Date): string {
    return moment(new Date(date)).fromNow();
  }

  static qualify(input: string): any {
    const date = new Date(input);

    let out = '';
    if (date < this.today) {
      out = 'past';
    } else if (date < this.tomorrow) {
      out = 'today';
    }
    else if (date < this.nextMonday) {
      out = 'thisWeek';
    }
    else if (date < this.nextNextMonday) {
      out = 'nextWeek';
    } else {
      out = 'after';
    }

    let locale = '';
    if (out === 'thisWeek' || out === 'nextWeek') {
      locale = this.locale[this.dayOfWeek[date.getDay()]];
    } else {
      locale = this.locale[out];
    }

    return {
      slug: moment(date).format("YYYY_MM_DD"),
      day: this.dayOfWeek[date.getDay()],
      locale: locale,
      date: date.getDate(),
      month: date.getMonth(),
      q: out
    };
  }
}