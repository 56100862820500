
  import { watchEffect } from "vue";
  import { Options, Vue } from "vue-class-component";

  import Multiselect from "@vueform/multiselect";

  import ApiService from "@/services/apiService";
  import { typesService } from "@/services/typesService";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  @Options({
    name: "LabelEditor",
    components: { Multiselect },
    props: {
      opt: {},
    },
  })
  export default class LabelEditor extends Vue {
    opt!: any;
    mode: string = "Create";

    name: string = "";
    type: string = "default";

    availabelTypes: any = {};

    labelsStore: any = null;
    ui: any = null;

    async mounted() {
      this.labelsStore = useLabels();
      this.ui = useUI();
      this.availabelTypes = typesService.availableTypes;
      watchEffect(() => {
        if (this.opt.mode) {
          this.mode = this.opt.mode;
        } else {
          this.mode = "Create";
        }

        switch (this.mode) {
          case "Update":
            this.name = this.opt.label.name;
            this.type = this.opt.label.type;
            break;
          default:
            this.name = "";
            this.type = "default";
            break;
        }
      });
    }

    get canSave() {
      return this.name.length;
    }

    get saveCtaClasses() {
      let out = ["save-cta"];
      if (this.canSave) {
        out.push("enabled");
      }
      return out.join(" ");
    }

    save() {
      if (this.canSave) {
        if (this.mode === "Update") {
          this.saveModification();
        } else {
          this.sendLabel();
        }
      }
    }

    sendLabel() {
      this.ui.spinner.show();
      ApiService.post("labels", {
        name: this.name,
        type: this.type,
      })
        .then((response) => {
          this.$success(response);
          this.labelsStore.fetch();
          this.ui.main.close();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    saveModification() {
      this.ui.spinner.show();
      ApiService.put("labels/" + this.opt.label._id, {
        name: this.name,
        type: this.type,
      })
        .then((response) => {
          this.$success(response);
          this.labelsStore.fetch();
          this.ui.main.close();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }
  }
