<template>
  <div class="datetime-picker">
    <v-date-picker
      v-model="model"
      mode="dateTime"
      locale="fr"
      is24hr
      is-dark
      :minute-increment="5"
      :attributes="attributes"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="i-cntnr">
          <CloseCircleIcon class="cleaner" @click="clean" />
          <input
            :value="inputValue"
            v-on="inputEvents"
            readonly
            :placeholder="placeholder"
            @change="onChange"
          />
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script lang="ts">
  import { watchEffect } from "vue";
  import { Options, Vue } from "vue-class-component";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";
  import DatePicker from "v-calendar";

  @Options({
    name: "DatetimePicker",
    components: { CloseCircleIcon, DatePicker },
    props: {
      modelValue: {
        required: true,
      },
      placeholder: String,
    },
    emits: ["update:modelValue"],
  })
  export default class DatetimePicker extends Vue {
    modelValue!: any;
    model: any = null;
    placeholder!: string;

    attributes: any = [
      {
        dot: true,
        dates: [new Date()],
      },
    ];

    async created() {
      this.model = this.modelValue ? new Date(this.modelValue) : null;
      watchEffect(() => {
        if (this.model) {
          this.$emit("update:modelValue", this.model);
        }
      });
    }

    clean() {
      this.model = null;
      this.$emit("update:modelValue", this.model);
    }
  }
</script>

<style scoped lang="scss">
  .datetime-picker {
    .i-cntnr {
      @apply relative;
      .cleaner {
        @apply absolute right-1 top-3;
      }
    }
    &:not(:last-child) {
      @apply mb-5;
    }
  }
</style>
