
  import { Options, Vue } from "vue-class-component";
  import MenuIcon from "vue-ionicons/dist/md-menu.vue";

  import { useAuth } from "@/state/authStore";
  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "Menu",
    components: { MenuIcon },
    props: {},
  })
  export default class Menu extends Vue {
    isMenuActive: boolean = false;

    auth: any = null;
    labelsStore: any = null;
    ui: any = null;

    currentMenuItem: any = null;
    labelsMenuItem: any = {
      isActive: false,
    };
    agendaMenuItem: any = {
      isActive: false,
    };
    allEntriesMenuItem: any = {
      title: "Tout voir",
      show: ["deleted", "orders", "labels", "onlyParents"],
      order: {
        createdAt: "desc",
      },
      params: {
        onlyParents: true,
      },
      addCntnr: true,
    };

    async mounted() {
      this.auth = useAuth();
      this.labelsStore = useLabels();
      this.ui = useUI();
    }

    get menuItems() {
      let output: any[] = [
        {
          title: "Derniers ajouts",
          show: ["labels"],
          order: {
            createdAt: "desc",
          },
          params: {
            onlyParents: false,
          },
          addCntnr: true,
        },
        {
          title: "Dernières supp",
          show: ["labels"],
          class: "separator-after",
          order: {
            deletedAt: "desc",
          },
          params: {
            onlyDeleted: true,
          },
          addCntnr: true,
        },
        {
          title: "Sans label",
          show: ["deleted", "orders", "onlyParents"],
          order: {
            createdAt: "desc",
          },
          params: {
            labelIds: ["none"],
          },
          addCntnr: true,
        },
      ];

      if (this.labelsStore) {
        this.labelsStore.state.a.forEach((element: Label) => {
          output.push({
            title: element.name,
            show: ["deleted", "orders", "onlyParents"],
            order: {
              createdAt: "desc",
            },
            params: {
              labelIds: [element._id],
              onlyParents: true,
            },
            addCntnr: {
              labels: [element._id],
            },
          });
        });
      }

      return output;
    }

    menuItemClass(menuItem: any) {
      let output: string[] = [];

      if (menuItem.isActive) output.push("active");
      if (menuItem.class) output.push(menuItem.class);

      return output.concat(" ");
    }

    showEntries(menuItem: any) {
      this.selectMenuItem(menuItem);
      this.ui.main.set("entriesSearch", {
        origin: menuItem,
        data: {
          title: menuItem.title,
          show: menuItem.show,
          order: menuItem.order,
          params: menuItem.params,
        },
        addCntnr: menuItem.addCntnr,
      });
      this.isMenuActive = false;
    }

    showAllEntries() {
      this.showEntries(this.allEntriesMenuItem);
    }

    showAgenda() {
      this.selectMenuItem(this.agendaMenuItem);
      this.ui.main.set("entriesAgenda", {
        origin: this.agendaMenuItem,
        data: null,
        addCntnr: true,
      });
      this.isMenuActive = false;
    }

    manageLabels() {
      this.selectMenuItem(this.labelsMenuItem);
      this.ui.main.set("labelsList", {
        origin: this.labelsMenuItem,
        data: null,
        addCntnr: false,
      });
      this.isMenuActive = false;
    }

    selectMenuItem(menuItem: any) {
      if (this.currentMenuItem) {
        this.currentMenuItem.isActive = false;
      }
      this.currentMenuItem = menuItem;
      this.currentMenuItem.isActive = true;
    }

    disconnect() {
      this.auth.disconnect();
    }
  }
