<template>
  <div class="todolist-actions">
    <div v-if="isDone" @click="removeMark">
      <span v-if="mode === 'text'">Non traité</span>
      <RemoveCircleIcon v-if="mode === 'icon'" w="20" h="20" />
    </div>
    <div v-else @click="mark">
      <span v-if="mode === 'text'">Traité</span>
      <CheckmarkCircleIcon v-if="mode === 'icon'" w="20" h="20" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import CheckmarkCircleIcon from "vue-ionicons/dist/md-checkmark-circle.vue";
  import RemoveCircleIcon from "vue-ionicons/dist/md-remove-circle.vue";

  import EntriesHelper from "@/services/entriesHelper";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "TodolistActions",
    components: {
      CheckmarkCircleIcon,
      RemoveCircleIcon,
    },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
      mode: String,
    },
    emits: ["refresh"],
  })
  export default class TodolistActions extends Vue {
    entry!: Entry;
    mode: string = "icon"; // text

    ui: any = null;

    async mounted() {
      this.ui = useUI();
    }

    get isDone() {
      return this.entry.xtra && this.entry.xtra.done;
    }

    removeMark() {
      this.ui.spinner.show();
      EntriesHelper.updateEntriesXtra(this.entry, {
        done: null,
      })
        .then((response: any) => {
          this.$success(response);
          this.$emit("refresh", response.data.data);
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    mark() {
      this.ui.spinner.show();
      EntriesHelper.updateEntriesXtra(this.entry, {
        done: true,
      })
        .then((response: any) => {
          this.$success(response);
          this.$emit("refresh", response.data.data);
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }
  }
</script>

<style scoped lang="scss">
  .task-actions {
  }
</style>
