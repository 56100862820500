
  import { Options, Vue } from "vue-class-component";
  import LoginForm from "@/components/Basis/LoginForm.vue";
  import Nestor from "@/components/Nestor.vue";
  import { useAuth } from "@/state/authStore";

  @Options({
    components: {
      LoginForm,
      Nestor,
    },
  })
  export default class App extends Vue {
    auth: any = null;

    async mounted() {
      this.auth = useAuth();
    }

    get isConnected() {
      return this.auth && this.auth.state.connected;
    }
  }
