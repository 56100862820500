<template>
  <div class="label-brief" v-if="label">
    {{ label.name }}
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import { useLabels } from "@/state/labelsStore";

  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "LabelBrief",
    props: {
      labelId: {
        required: true,
      },
    },
  })
  export default class LabelBrief extends Vue {
    labelId!: number;

    labelsStore: any = null;

    async created() {
      this.labelsStore = useLabels();
    }

    get label() {
      let out = null;
      if (this.labelsStore && this.labelsStore.state) {
        out = this.labelsStore.state.i[this.labelId];
      }
      return out;
    }
  }
</script>

<style scoped lang="scss">
  .label-brief {
    color: $secondary;
  }
</style>
