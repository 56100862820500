<template>
  <div class="confirm-pop-up">
    <div class="text">Êtes-vous sûr ?</div>
    <div class="response">
      <div class="cta ko" @click="ko">Non</div>
      <div class="cta ok" @click="ok">Oui</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import { useUI } from "@/state/uiStore";

  @Options({
    name: "ConfirmPopup",
    components: {},
    props: {},
  })
  export default class ConfirmPopup extends Vue {
    ui: any = null;

    async created() {
      this.ui = useUI();
    }

    ko() {
      if (this.ui.state.confirm.koCb) {
        this.ui.state.confirm.koCb();
      }
      this.ui.confirm.hide();
    }

    ok() {
      this.ui.state.confirm.okCb();
      this.ui.confirm.hide();
    }
  }
</script>

<style scoped lang="scss">
  .confirm-pop-up {
    @apply px-8 py-6;
    background-color: $level_2;
    .text {
      @apply text-lg mb-4;
    }
    .response {
      @apply flex justify-between;
      .cta {
        @apply py-2 px-6 uppercase tracking-widest font-medium text-sm;
        &.ko {
          @apply mr-4;
          background-color: $error;
          color: $error_alt;
        }
        &.ok {
          background-color: $success;
          color: $success_alt;
        }
      }
    }
  }
</style>
