import { reactive, provide, inject, readonly } from 'vue';

export const createUIStore = () => {
    const state = reactive({
        main: {
            mode: null,
            origin: null,
            data: null,
            addCntnr: null,
            ctrlBack: null,
        },
        spinner: {
            active: false
        },
        confirm: {
            active: false,
            okCb: null,
            koCb: null,
        }
    });

    const onBack = (event) => {
        if (event.state && event.state.origine === "Nestor") {
            state.main.mode = event.state.opt.mode;
            state.main.data = event.state.opt.data;
            state.main.addCntnr = event.state.opt.addCntnr;
            state.main.ctrlBack = null;
        }
    };

    window.onpopstate = (event) => {
        if (state.main.ctrlBack) {
            state.main.ctrlBack(() => {
                onBack(event);
            });
        } else {
            onBack(event);
        }
    };
    const main = {
        set: (mode, opt, isReplace = false) => {
            if (state.main.mode !== null) {
                if (state.main.origin && state.main.origin.isActive) {
                    state.main.origin.isActive = false;
                }
            }
            state.main.mode = mode;
            state.main.origin = opt.origin ?? null;
            state.main.data = opt.data ?? null;
            state.main.addCntnr = opt.addCntnr ?? null;
            state.main.ctrlBack = null;

            const eventState = {
                origine: "Nestor",
                opt: JSON.parse(JSON.stringify({
                    mode: state.main.mode,
                    data: state.main.data,
                    addCntnr: state.main.addCntnr,
                })),
            };
            if (isReplace) {
                history.replaceState(eventState, opt.mode);
            } else {
                history.pushState(eventState, opt.mode);
            }
        },
        setBackCtrl(func) {
            state.main.ctrlBack = func;
        },
        close: () => {
            state.main.ctrlBack = null;
            history.back();
        },
    }
    const spinner = {
        toggle: () => {
            state.spinner.active = !state.spinner.active;
        },
        show: () => {
            state.spinner.active = true;
        },
        hide: () => {
            state.spinner.active = false;
        }
    }
    const confirm = {
        show: (okCb, koCb = null) => {
            state.confirm.active = true;
            state.confirm.okCb = okCb;
            state.confirm.koCb = koCb;
        },
        hide: () => {
            state.confirm.active = false;
            state.confirm.okCb = null;
            state.confirm.koCb = null;
        }
    }

    return { main, spinner, confirm, state: readonly(state) };
};


export const uiSymbol = Symbol('ui');
export const useUI = () => inject(uiSymbol);
export const provideUI = () => provide(uiSymbol, createUIStore());