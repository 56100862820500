<template>
  <div class="entries-search">
    <div class="header">
      <span class="title">{{ opt.title }}</span>
      <div v-if="entries.length !== total" class="count">
        {{ entries.length }} / {{ total }} entrées
      </div>
      <div v-else class="count">{{ total }} entrées</div>
    </div>
    <div
      class="filters"
      v-if="
        isShown('deleted') ||
        isShown('labels') ||
        isShown('orders') ||
        isShown('onlyParents')
      "
    >
      <div>
        <div class="i-cntnr">
          <input type="text" v-model="searchStr" />
        </div>
        <label label-for="entries-list-deleted" v-if="isShown('deleted')">
          <input
            type="checkbox"
            id="entries-list-deleted"
            v-model="searchConfig.params.withDeleted"
          />
          Supprimées
        </label>
        <label label-for="entries-list-only-parents" v-if="isShown('onlyParents')">
          <input
            type="checkbox"
            id="entries-list-only-parents"
            v-model="searchConfig.params.onlyParents"
          />
          Que 1er niveau
        </label>
        <LabelsSelector
          v-if="isShown('labels')"
          :selectedLabels="selectedLabels"
          :dropdown-mode="true"
        />
      </div>
      <div class="orders">
        <OrdersSelector v-if="isShown('orders')" v-model="selectedOrder" />
      </div>
    </div>
    <EntriesList v-if="total" :entries="entries" :total="total" @getMore="getNextPage" />
    <div class="placeholder" v-else-if="!isLoading">Aucune entrée</div>
  </div>
</template>

<script lang="ts">
  import axios from "axios";
  import { watchEffect, reactive, watch } from "vue";
  import { Options, Vue } from "vue-class-component";

  import EntriesList from "@/components/Entries/EntriesList.vue";
  import LabelsSelector from "@/components/Labels/LabelsSelector.vue";
  import OrdersSelector from "@/components/Basis/OrdersSelector.vue";

  import ApiService from "@/services/apiService";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  // eslint-disable-next-line no-unused-vars
  import ApiList from "@/interfaces/apiList.interface";

  @Options({
    name: "EntriesSearch",
    components: { EntriesList, LabelsSelector, OrdersSelector },
    props: {
      opt: {},
    },
  })
  export default class EntriesSearcg extends Vue {
    opt!: any;

    searchConfig: any = null;
    lastSeachConfig: string = "";
    total: number = 0;
    entries: Entry[] = [];
    skip: number = 0;
    limit: number = 15;

    selectedLabels: string[] = [];
    selectedOrder: string = "createdAt_desc";
    searchStr: string = "";

    isLoading: boolean = false;
    askLoadMore: boolean = false;
    cancelSource: any = null;

    ui: any = null;

    async created() {
      this.searchConfig = reactive({
        skip: 0,
        limit: 15,
        order: {},
        params: {},
      });
      watch(
        () => this.searchConfig,
        () => {
          const searchConfigStr = JSON.stringify(this.searchConfig);
          if (this.lastSeachConfig !== searchConfigStr) {
            this.lastSeachConfig = searchConfigStr;
            this.getList();
          }
        },
        { deep: true }
      );
    }

    async mounted() {
      this.ui = useUI();

      watchEffect(() => this.init());
      watchEffect(() => this.watchActions());
    }

    init() {
      this.selectedLabels = [];
      if (this.opt.order) {
        let key = Object.keys(this.opt.order)[0];
        this.selectedOrder = key + "_" + this.opt.order[key];
      } else {
        this.selectedOrder = "createdAt_desc";
      }
      let defaultP = { name: null };
      if (this.opt.params) {
        this.searchConfig.params = Object.assign(defaultP, this.opt.params);
      } else {
        this.searchConfig.params = defaultP;
      }
    }

    watchActions() {
      this.searchConfig.order = Object.fromEntries([this.selectedOrder.split("_")]);

      if (this.isShown("labels")) {
        if (this.selectedLabels.length > 0) {
          this.searchConfig.params.labelIds = this.selectedLabels;
        } else {
          this.searchConfig.params.labelIds = null;
        }
      }

      if (this.searchStr.length > 2) {
        this.searchConfig.params.name = this.searchStr;
      } else {
        this.searchConfig.params.name = null;
      }

      if (this.askLoadMore) {
        this.searchConfig.skip += this.searchConfig.limit;
        this.askLoadMore = false;
      } else {
        this.searchConfig.skip = 0;
      }
    }

    getList() {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.cancelSource = axios.CancelToken.source();
      this.isLoading = true;
      this.ui.spinner.show();
      return ApiService.get("entries", this.searchConfig, this.cancelSource)
        .then((response) => {
          this.cancelSource = null;
          this.skip = response.data.skip;
          this.limit = response.data.limit;
          this.total = response.data.total;
          if (this.skip === 0) {
            this.entries = response.data.items;
          } else {
            this.entries = [...this.entries, ...response.data.items];
          }
          this.isLoading = false;
          this.ui.spinner.hide();
        })
        .catch((error) => {
          this.$error(error);
        });
    }

    getNextPage() {
      if (!this.isLoading && this.skip + this.limit < this.total) {
        this.askLoadMore = true;
      }
    }

    isShown(partName: string) {
      return this.opt.show.indexOf(partName) !== -1;
    }
  }
</script>

<style scoped lang="scss">
  .entries-search {
    @apply flex flex-col h-full;
    .header {
      .count {
        @apply text-gray-300;
      }
    }
    .filters {
      @apply flex justify-between items-center px-4 py-2;
      background-color: $level-1;
      label {
        @apply text-xs block;
        &:not(:last-child) {
          @apply mb-1;
        }
      }
      > .orders {
        min-width: 200px;
      }
    }
    .entries-list {
      @apply flex-1;
    }

    .placeholder {
      @apply absolute inset-0;
      @apply flex justify-center items-center text-gray-400 text-3xl;
    }
  }
</style>
