
  // FROM epic-spinners
  // https://github.com/epicmaxco/epic-spinners/blob/master/src/components/lib/SpringSpinner.vue
  import { Options, Vue } from "vue-class-component";
  import { watchEffect } from "vue";

  @Options({
    name: "SpringSpinner",
    props: {
      animationDuration: {
        type: Number,
        default: 3000,
      },
      size: {
        type: Number,
        default: 70,
      },
      color: {
        type: String,
        default: "#941B0C",
      },
    },
  })
  export default class SpringSpinner extends Vue {
    animationDuration!: number;
    size!: number;
    color!: string;

    animationName: string = `spring-spinner-animation-${Date.now()}`;

    async mounted() {
      watchEffect(() => this.updateAnimation());
    }

    beforeUnmount() {
      this.removeKeyframes(this.animationName);
    }

    get spinnerStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
      };
    }

    get spinnerPartStyle() {
      return {
        height: `${this.size / 2}px`,
        width: `${this.size}px`,
      };
    }

    get rotatorStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderRightColor: this.color,
        borderTopColor: this.color,
        borderWidth: `${this.size / 7}px`,
        animationDuration: `${this.animationDuration}ms`,
        animationName: this.animationName,
      };
    }
    updateAnimation() {
      this.removeKeyframes(this.animationName);
      this.appendKeyframes(this.animationName, this.generateKeyframes());
    }

    generateKeyframes() {
      return `
            0% {
            border-width: ${this.size / 7}px;
            }
            25% {
            border-width: ${this.size / 23.33}px;
            }
            50% {
            transform: rotate(115deg);
            border-width: ${this.size / 7}px;
            }
            75% {
            border-width: ${this.size / 23.33}px;
            }
            100% {
            border-width: ${this.size / 7}px;
            }`;
    }

    appendKeyframes(name: string, frames: string) {
      const sheet = document.createElement("style");
      if (!sheet) {
        return;
      }
      sheet.setAttribute("id", name);
      sheet.innerHTML = `@keyframes ${name} {${frames}}`;
      document.body.appendChild(sheet);
    }
    removeKeyframes(name: string) {
      const sheet = document.getElementById(name);
      if (!sheet) {
        return;
      }
      const sheetParent = sheet.parentNode;
      if (!sheetParent) {
        return;
      }
      sheetParent.removeChild(sheet);
    }
  }
