<template>
  <div class="labels-search">
    <ul class="selecteds">
      <li
        v-for="(label, index) in selecteds"
        :key="label._id"
        @click="removeLabel(index)"
      >
        {{ label.name }}
      </li>
    </ul>
    <div class="search-cntnr">
      <div class="i-cntnr">
        <input
          :value="searchStr"
          placeholder="Nom du label"
          @input="(evt) => (searchStr = evt.target.value)"
        />
        <SearchIcon />
      </div>
      <ul class="search-result">
        <li v-for="label in searchResult" :key="label._id" @click="selectLabel(label)">
          {{ label.name }}
        </li>
        <li v-if="isCreatable" class="create">
          <span @click="sendLabel">Créer ce label</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import SearchIcon from "vue-ionicons/dist/md-search.vue";

  import ApiService from "@/services/apiService";
  import { typesService } from "@/services/typesService";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "LabelsSearch",
    components: { SearchIcon },
    props: {
      modelValue: {
        type: Array as () => String[],
      },
    },
    emits: ["update:modelValue"],
  })
  export default class LabelsSearch extends Vue {
    modelValue!: string[];

    searchStr: string = "";
    selecteds: Label[] = [];

    labelsStore: any = null;
    ui: any = null;

    async mounted() {
      this.labelsStore = useLabels();
      this.ui = useUI();
      this.modelValue.forEach((e: string) => {
        this.selecteds.push(this.labelsStore.state.i[e]);
      });
    }

    get searchResult() {
      let output: Label[] = [];

      if (this.labelsStore && this.searchStr.length > 0) {
        for (const e of this.labelsStore.state.a) {
          if (
            e.name.toLowerCase().indexOf(this.searchStr.toLowerCase()) !== -1 &&
            this.selecteds.findIndex((i) => i._id === e._id) === -1
          ) {
            output.push(e);
          }
          if (output.length === 5) {
            break;
          }
        }
      }

      return output;
    }

    get isCreatable() {
      return (
        this.searchStr.length > 0 &&
        this.labelsStore.state.a.findIndex(
          (i: any) => i.name.toLowerCase() === this.searchStr.toLowerCase()
        ) === -1
      );
    }

    selectLabel(selected: Label) {
      this.searchStr = "";
      this.selecteds.push(selected);
      this.updateModel();
    }

    removeLabel(toRemove: number) {
      this.selecteds.splice(toRemove, 1);
      this.updateModel();
    }

    updateModel() {
      const ids = this.selecteds.map((a: Label) => a._id);
      this.$emit("update:modelValue", ids);
    }

    sendLabel() {
      this.ui.spinner.show();
      ApiService.post("labels", {
        name: this.searchStr,
        type: typesService.vals.Default,
      })
        .then((response) => {
          this.$success(response);
          this.labelsStore.fetch(() => {
            this.selectLabel(this.labelsStore.state.i[response.data.data._id]);
          });
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }
  }
</script>

<style scoped lang="scss">
  .labels-search {
    ul.selecteds {
      @apply px-5 flex flex-wrap;
      li {
        @apply py-1 px-3 rounded mb-3;

        background-color: $level_3;
        &:not(:last-child) {
          @apply mr-2;
        }
      }
    }
    .search-cntnr {
      @apply relative;
      .i-cntnr {
        @apply relative;
        input {
        }
        .ion {
          @apply absolute right-7 top-2/4;
          transform: translateY(-50%);
        }
      }
      ul.search-result {
        @apply mx-5 absolute top-full right-0 left-0 z-10;
        background-color: $level_3;
        li {
          @apply p-3;
          &.create {
            background-color: $level_4;
            color: $secondary;
          }
        }
      }
    }
  }
</style>
