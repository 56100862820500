
  import { Options, Vue } from "vue-class-component";
  import ExpandIcon from "vue-ionicons/dist/md-expand.vue";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";
  import CreateIcon from "vue-ionicons/dist/md-create.vue";

  @Options({
    name: "Textarea",
    components: { CloseCircleIcon, CreateIcon, ExpandIcon },
    props: {
      modelValue: String,
      placeholder: String,
    },
    emits: ["update:modelValue"],
  })
  export default class Textarea extends Vue {
    modelValue!: string;
    placeholder!: string;

    isFullScreen: boolean = false;
    open: boolean = false;

    async mounted() {
      if (this.modelValue) {
        this.open = this.modelValue.length > 0;
      }
    }

    get getCntnrClass() {
      let output = [];

      if (this.isFullScreen) {
        output.push("fullscreen");
      }
      return output.concat(" ");
    }

    enable() {
      this.open = true;
    }

    disable() {
      this.open = false;
    }

    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    }
  }
