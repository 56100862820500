<template>
  <div class="orders-selector">
    <Multiselect
      v-model="model"
      :can-deselect="false"
      @change="sendUpdate"
      :options="availableOrders"
    />
  </div>
</template>

<script lang="ts">
  import { watchEffect } from "vue";
  import { Options, Vue } from "vue-class-component";
  import Multiselect from "@vueform/multiselect";

  @Options({
    name: "OrdersSelector",
    components: { Multiselect },
    props: {
      modelValue: String,
    },
    emits: ["update:modelValue"],
  })
  export default class OrdersSelector extends Vue {
    modelValue!: string;

    model!: string;

    async created() {
      this.model = this.modelValue;
      watchEffect(() => (this.model = this.modelValue));
    }

    availableOrders: any = {
      createdAt_desc: "Création récente",
      createdAt_asc: "Création ancienne",
      name_asc: "A > Z",
      name_desc: "Z > A",
      updatedAt_desc: "Modification récente",
      updatedAt_asc: "Modification ancienne",
      deletedAt_desc: "Suppression récente",
      deletedAt_asc: "Suppression ancienne",
    };
    sendUpdate(value: any) {
      if (value) this.$emit("update:modelValue", value);
    }
  }
</script>

<style lang="scss">
  .orders-selector {
  }
</style>
