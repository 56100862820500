<template>
  <div class="event-details">
    <div>
      <span>À faire après le :</span>
      <span>{{ after }}</span>
    </div>
    <div>
      <span>À faire le :</span>
      <span>{{ plannedAt }}</span>
    </div>
    <div>
      <span>À faire avant le :</span>
      <span>{{ before }}</span>
    </div>
    <div>
      <span>Fait le :</span>
      <span>{{ completedAt }}</span>
    </div>
    <div v-if="entry.xtra && entry.xtra.completedAt" class="completed-since">
      ({{ completedSince }})
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  import DateHelper from "@/services/dateHelper.ts";

  @Options({
    name: "EventDetails",
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
    },
  })
  export default class EventDetails extends Vue {
    entry!: Entry;

    get completedAt() {
      let output = "Pas encore";
      if (this.entry.xtra && this.entry.xtra.completedAt) {
        output = DateHelper.date(this.entry.xtra.completedAt);
      }
      return output;
    }
    get completedSince() {
      return this.entry.xtra && this.entry.xtra.completedAt
        ? DateHelper.since(this.entry.xtra.completedAt)
        : "";
    }

    get plannedAt() {
      let output = "Non planifié";
      if (this.entry.xtra && this.entry.xtra.plannedAt) {
        output = DateHelper.date(this.entry.xtra.plannedAt);
      }
      return output;
    }

    get after() {
      let output = "Pas de date minimale";
      if (this.entry.xtra && this.entry.xtra.after) {
        output = DateHelper.date(this.entry.xtra.after);
      }
      return output;
    }

    get before() {
      let output = "Pas de date maximale";
      if (this.entry.xtra && this.entry.xtra.before) {
        output = DateHelper.date(this.entry.xtra.before);
      }
      return output;
    }
  }
</script>

<style scoped lang="scss">
  .event-details {
    > div {
      @apply flex justify-between;
      &.completed-since {
        @apply justify-end;
      }
    }
  }
</style>
