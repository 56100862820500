import Entry from "@/interfaces/entry.interface";

import ApiService from "@/services/apiService";

export default abstract class EntriesHelper {
    static updateEntriesXtra(entry: Entry, xtra: any) : Promise<any> {
        let toSend: any = {};
        if (entry.xtra) {
            toSend = JSON.parse(JSON.stringify(entry.xtra));
        }

        Object.keys(xtra).forEach((el: string) => {
            toSend[el] = xtra[el];
        });

        return ApiService.put("entries/xtra/" + entry._id, toSend);
    }
}