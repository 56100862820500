
  import { Options, Vue } from "vue-class-component";

  import CheckmarkCircleIcon from "vue-ionicons/dist/md-checkmark-circle.vue";
  import draggable from "vuedraggable";
  import RemoveCircleIcon from "vue-ionicons/dist/md-remove-circle.vue";

  import EntriesHelper from "@/services/entriesHelper";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "TodolistDetails",
    components: {
      CheckmarkCircleIcon,
      draggable,
      RemoveCircleIcon,
    },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
    },
    emits: ["refresh"],
  })
  export default class TodolistDetails extends Vue {
    entry!: Entry;

    ui: any = null;
    updateCtrl: string = "";

    async mounted() {
      this.ui = useUI();
      this.setCtrl(this.entry);
    }

    get done() {
      let output = "Non";
      if (this.entry.xtra && this.entry.xtra.done) {
        output = this.entry.xtra.done ? "Oui" : "Non";
      }
      return output;
    }

    get hasChange() {
      let current = JSON.stringify(this.entry.xtra.tasks);
      return current !== this.updateCtrl;
    }

    get saveCtaClasses() {
      let out = ["save-cta"];
      if (this.hasChange) {
        out.push("enabled");
      }
      return out.join(" ");
    }

    setCtrl(entry: Entry) {
      this.updateCtrl = JSON.stringify(entry.xtra.tasks);
    }

    saveChange() {
      if (this.hasChange) {
        this.ui.spinner.show();
        EntriesHelper.updateEntriesXtra(this.entry, {
          tasks: this.entry.xtra.tasks,
        })
          .then((response: any) => {
            this.$success(response);
            this.setCtrl(response.data.data);
            this.$emit("refresh", response.data.data);
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      }
    }
  }
