<template>
  <div class="entries-list nestor-scrollbar" ref="list-cntnr">
    <ul class="list" ref="list">
      <li v-for="entry in entries" :key="entry._id">
        <EntryBrief
          :entry="entry"
          @refresh="
            (updated) => {
              refreshItem(entry, updated);
            }
          "
          @manageSelect="
            (isSelected) => {
              manageSelect(entry, isSelected);
            }
          "
        />
      </li>
      <li v-if="entries.length === total" class="list-end">Tout est là</li>
      <li v-else class="list-end">Chargement</li>
    </ul>
    <div class="selection-cntnr" v-if="selectedEntries.length > 0">
      <div class="head">
        <span>{{ selectionStr }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import EntryBrief from "@/components/Entries/EntryBrief.vue";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EntriesList",
    components: { EntryBrief },
    props: {
      entries: {},
      total: {},
    },
    emits: ["getMore"],
  })
  export default class EntriesList extends Vue {
    entries!: Entry[];

    total!: number;

    selectedEntries: Entry[] = [];

    loadMoreThreshold: number = 25;
    scrollCntnr: any;
    askLoadMore: boolean = false;

    ui: any = null;

    get selectionStr() {
      let out = "";
      if (this.selectedEntries.length === 1) {
        out = "1 sélectionnée";
      } else if (this.selectedEntries.length > 1) {
        out = this.selectedEntries.length + " sélectionnées";
      }
      return out;
    }

    async mounted() {
      this.ui = useUI();
      this.scrollCntnr = this.$refs["list-cntnr"];
      this.scrollCntnr.addEventListener("scroll", this.onScroll);
    }

    manageSelect(entry: Entry, isSelected: boolean) {
      if (isSelected) {
        this.selectedEntries.push(entry);
      } else {
        const entryIndex = this.selectedEntries.findIndex((e: Entry) => e === entry);
        this.selectedEntries.splice(entryIndex, 1);
      }
    }

    refreshItem(old: Entry, updated: Entry) {
      old.xtra = updated.xtra;
    }

    beforeUnmount() {
      this.scrollCntnr.removeEventListener("scroll", this.onScroll);
    }

    onScroll() {
      var listEl: any = this.$refs["list"];

      if (listEl) {
        // console.log("onScroll");

        var cntnrHeight = this.scrollCntnr.offsetHeight;

        var listOriginDistanceToTop = this.scrollCntnr.offsetTop;
        var listMarginTop = listEl.getBoundingClientRect().top;

        var scrollPosition = listMarginTop - listOriginDistanceToTop - cntnrHeight;
        var distanceFromBot = scrollPosition + listEl.offsetHeight;

        if (distanceFromBot < this.loadMoreThreshold) {
          // console.log("getMore");
          this.$emit("getMore");
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .entries-list {
    @apply overflow-y-auto relative max-h-full;

    .list {
      > li {
        &:not(:last-child) {
          @apply mb-2;
        }
        &.list-end {
          @apply text-center py-5 text-xs uppercase tracking-widest;
          color: $secondary;
        }
      }
    }
    .selection-cntnr {
      @apply py-3 px-6;
      background-color: $secondary;
    }
  }
</style>
