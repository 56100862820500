
  import { watchEffect } from "vue";
  import { Options, Vue } from "vue-class-component";

  import EntryBrief from "@/components/Entries/EntryBrief.vue";
  import EntriesList from "@/components/Entries/EntriesList.vue";
  import LabelBrief from "@/components/Labels/LabelBrief.vue";
  import XtraDetails from "@/components/Types/XtraDetails.vue";
  import XtraActions from "@/components/Types/XtraActions.vue";
  import EntryActions from "@/components/Entries/EntryActions.vue";

  import CloseCircle from "vue-ionicons/dist/md-close-circle-outline.vue";
  import Checkmark from "vue-ionicons/dist/md-checkmark.vue";
  import DoneAll from "vue-ionicons/dist/md-done-all.vue";
  import MoreIcon from "vue-ionicons/dist/md-more.vue";

  import ApiService from "@/services/apiService";
  import DateHelper from "@/services/dateHelper.ts";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EntryDetails",
    components: {
      Checkmark,
      CloseCircle,
      DoneAll,
      EntryActions,
      EntryBrief,
      EntriesList,
      LabelBrief,
      MoreIcon,
      XtraDetails,
      XtraActions,
    },
    props: {
      entryId: {
        required: true,
        type: String,
      },
    },
  })
  export default class EntryDetails extends Vue {
    entryId!: string;

    entry: Entry | null = null;
    isLoading: Boolean = true;
    showXtraRaw: Boolean = false;
    openActions: Boolean = false;

    ui: any = null;

    get deletedAt() {
      return this.entry && this.entry.deletedAt
        ? DateHelper.date(this.entry.deletedAt)
        : "";
    }

    get deletedSince() {
      return this.entry && this.entry.deletedAt
        ? DateHelper.since(this.entry.deletedAt)
        : "";
    }

    get updatedAt() {
      return this.entry && this.entry.updatedAt
        ? DateHelper.date(this.entry.updatedAt)
        : "";
    }

    get updatedSince() {
      return this.entry && this.entry.updatedAt
        ? DateHelper.since(this.entry.updatedAt)
        : "";
    }

    get createdAt() {
      return this.entry && this.entry.createdAt
        ? DateHelper.date(this.entry.createdAt)
        : "";
    }

    get createdSince() {
      return this.entry && this.entry.createdAt
        ? DateHelper.since(this.entry.createdAt)
        : "";
    }

    async created() {
      this.ui = useUI();
      watchEffect(() => this.loadEntry());
    }

    refresh(refreshed: Entry) {
      this.openActions = false;
      this.entry = refreshed;
    }

    loadEntry() {
      this.isLoading = true;
      this.ui.spinner.show();
      ApiService.get("entries/" + this.entryId)
        .then((response: any) => {
          this.entry = response.data;
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.ui.spinner.hide();
        });
    }
  }
