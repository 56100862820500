
  import { Options, Vue } from "vue-class-component";

  import EntryBrief from "@/components/Entries/EntryBrief.vue";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EntriesList",
    components: { EntryBrief },
    props: {
      entries: {},
      total: {},
    },
    emits: ["getMore"],
  })
  export default class EntriesList extends Vue {
    entries!: Entry[];

    total!: number;

    selectedEntries: Entry[] = [];

    loadMoreThreshold: number = 25;
    scrollCntnr: any;
    askLoadMore: boolean = false;

    ui: any = null;

    get selectionStr() {
      let out = "";
      if (this.selectedEntries.length === 1) {
        out = "1 sélectionnée";
      } else if (this.selectedEntries.length > 1) {
        out = this.selectedEntries.length + " sélectionnées";
      }
      return out;
    }

    async mounted() {
      this.ui = useUI();
      this.scrollCntnr = this.$refs["list-cntnr"];
      this.scrollCntnr.addEventListener("scroll", this.onScroll);
    }

    manageSelect(entry: Entry, isSelected: boolean) {
      if (isSelected) {
        this.selectedEntries.push(entry);
      } else {
        const entryIndex = this.selectedEntries.findIndex((e: Entry) => e === entry);
        this.selectedEntries.splice(entryIndex, 1);
      }
    }

    refreshItem(old: Entry, updated: Entry) {
      old.xtra = updated.xtra;
    }

    beforeUnmount() {
      this.scrollCntnr.removeEventListener("scroll", this.onScroll);
    }

    onScroll() {
      var listEl: any = this.$refs["list"];

      if (listEl) {
        // console.log("onScroll");

        var cntnrHeight = this.scrollCntnr.offsetHeight;

        var listOriginDistanceToTop = this.scrollCntnr.offsetTop;
        var listMarginTop = listEl.getBoundingClientRect().top;

        var scrollPosition = listMarginTop - listOriginDistanceToTop - cntnrHeight;
        var distanceFromBot = scrollPosition + listEl.offsetHeight;

        if (distanceFromBot < this.loadMoreThreshold) {
          // console.log("getMore");
          this.$emit("getMore");
        }
      }
    }
  }
