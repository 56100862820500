<template>
  <div class="labels-list">
    <div class="header">
      <span class="title">Labels</span>
      <span class="count">{{ total }} Labels</span>
    </div>
    <div class="data-cntnr nestor-scrollbar">
      <div v-if="labelsStore">
        <div v-for="group in list" :key="group.letter">
          <div class="letter">
            {{ group.letter }}
          </div>
          <div v-for="label in group.labels" :key="label._id">
            <LabelDetails :label="label" :showAction="true" />
          </div>
        </div>
      </div>
      <div class="placeholder" v-else>Nothing to show</div>
    </div>
    <div class="add-label" @click="addLabel">Créer un label</div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import LabelDetails from "@/components/Labels/LabelDetails.vue";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  @Options({
    name: "LabelsList",
    components: { LabelDetails },
    props: {
      opt: {},
    },
  })
  export default class LabelsList extends Vue {
    opt!: any;

    labelsStore: any = null;
    ui: any = null;

    async mounted() {
      this.labelsStore = useLabels();
      this.ui = useUI();
    }

    get total() {
      let output = "?";
      if (this.labelsStore) {
        output = this.labelsStore.state.a.length;
      }
      return output;
    }

    get list() {
      let output: any[] = [];
      if (this.labelsStore && this.labelsStore.state.a.length > 0) {
        let copy = [...this.labelsStore.state.a];
        copy.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        let currentLetterObj: any = null;
        copy.forEach((e) => {
          let eFistLetter = e.name.toLowerCase()[0];
          if (currentLetterObj === null) {
            currentLetterObj = {
              letter: eFistLetter,
              labels: [e],
            };
          } else if (eFistLetter !== currentLetterObj.letter) {
            output.push(currentLetterObj);
            currentLetterObj = {
              letter: eFistLetter,
              labels: [e],
            };
          } else {
            currentLetterObj.labels.push(e);
          }
        });
        output.push(currentLetterObj);
      }
      return output;
    }

    addLabel() {
      this.ui.main.set("labelEditor", {
        data: {
          title: "Création",
          label: null,
          mode: "Create",
        },
        addCntnr: false,
      });
    }
  }
</script>

<style scoped lang="scss">
  .labels-list {
    @apply flex flex-col h-full;
    .header {
      .count {
        @apply text-gray-300;
      }
    }
    .data-cntnr {
      @apply flex-1 overflow-y-auto pb-20;
      .letter {
        @apply text-2xl pl-4 pt-2 uppercase;
        color: $secondary;
      }
    }
    .add-label {
      @apply w-full py-3 px-6 uppercase tracking-widest font-medium text-white text-center;
      background-color: $secondary;
    }
  }
</style>
