
  import { Options, Vue } from "vue-class-component";
  import Spinner from "@/components/Basis/Spinner.vue";
  import AuthService from "@/services/authService";
  import { useAuth } from "@/state/authStore";

  @Options({
    name: "LoginForm",
    components: { Spinner },
  })
  export default class LoginForm extends Vue {
    email: string = "";
    password: string = "";

    isWaiting: boolean = false;
    error: boolean = false;

    auth: any = null;

    async mounted() {
      this.auth = useAuth();
    }

    send() {
      this.isWaiting = true;
      AuthService.login(this.email, this.password)
        .then(() => {
          this.error = false;
          this.auth.fetch();
        })
        .catch((error: any) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.isWaiting = false;
        });
    }
  }
