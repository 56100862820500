
  import { Options, Vue } from "vue-class-component";
  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "TaskDetails",
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
    },
  })
  export default class TaskDetails extends Vue {
    entry!: Entry;

    get done() {
      let output = "Non";
      if (this.entry.xtra && this.entry.xtra.done) {
        output = this.entry.xtra.done ? "Oui" : "Non";
      }
      return output;
    }
  }
