<template>
  <Nestor v-if="isConnected" />
  <LoginForm v-else />
  <!-- <div class="test-color"></div> -->
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import LoginForm from "@/components/Basis/LoginForm.vue";
  import Nestor from "@/components/Nestor.vue";
  import { useAuth } from "@/state/authStore";

  @Options({
    components: {
      LoginForm,
      Nestor,
    },
  })
  export default class App extends Vue {
    auth: any = null;

    async mounted() {
      this.auth = useAuth();
    }

    get isConnected() {
      return this.auth && this.auth.state.connected;
    }
  }
</script>

<style lang="scss">
  #app {
    @apply text-gray-200;
    background-color: $level_0;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    .test-color {
      @apply absolute top-0 bottom-0 right-0;
      width: 100px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #fff;
        opacity: 0.11;
      }
    }
  }
</style>
