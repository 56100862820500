<template>
  <div class="entry-migrator">
    <div class="header">
      <span class="title">Changer de parent</span>
    </div>
    <div v-if="entry" class="parent-cntnr">
      <EntryBrief :entry="entry" />
    </div>
    <div v-if="entry && entry.parent" class="parent-cntnr">
      <div class="name">Parent actuel :</div>
      <EntryBrief :entry="entry.parent" />
    </div>
    <div class="new-parent-cntnr">
      <div class="name">Nouveau parent :</div>
      <EntryBrief v-if="newParent" :entry="newParent" />
      <div v-else class="placeholder">Non sélectionné</div>
    </div>
    <div class="search-cntnr">
      <div class="i-cntnr">
        <input placeholder="Nom de l'entrée" @keyup="onSearch" />
        <SearchIcon />
      </div>
      <div v-if="searchResult">
        <div v-if="searchResult.length === 0" class="placeholder">Aucun résultat</div>
        <ul class="search-result">
          <li
            v-for="sEntry in searchResult"
            :key="sEntry._id"
            @click="selectEntry(sEntry)"
          >
            <EntryBrief :entry="sEntry" :disableActions="true" />
          </li>
        </ul>
      </div>
      <div v-else class="placeholder">En attente de recherche</div>
    </div>
    <div :class="saveCtaClasses" @click="save">Enregistrer</div>
  </div>
</template>

<script lang="ts">
  import axios from "axios";

  import { Options, Vue } from "vue-class-component";

  import EntryBrief from "@/components/Entries/EntryBrief.vue";
  import SearchIcon from "vue-ionicons/dist/md-search.vue";
  import ApiService from "@/services/apiService";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  import { useUI } from "@/state/uiStore";

  @Options({
    name: "EntryMigrator",
    components: { EntryBrief, SearchIcon },
    props: {
      opt: {},
    },
  })
  export default class EntryMigrator extends Vue {
    opt!: any;

    entry: Entry | null = null;
    newParent: Entry | null = null;
    searchStr: string = "";
    searchResult: Entry[] | null = null;

    total: number = 0;
    cancelSource: any = null;

    ui: any = null;

    get canSave() {
      let out = false;
      if (this.entry) {
        if (this.newParent !== null) {
          if (this.entry.parent === null) {
            out = true;
          } else if (this.newParent._id !== this.entry.parent._id) {
            out = true;
          }
        }
      }
      return out;
    }

    get saveCtaClasses() {
      let out = ["save-cta"];
      if (this.canSave) {
        out.push("enabled");
      }
      return out.join(" ");
    }

    async mounted() {
      this.entry = this.opt.entry;
      this.ui = useUI();
    }

    selectEntry(sEntry: Entry) {
      this.newParent = sEntry;
    }

    onSearch(event: any) {
      const newValue = event.target.value;
      if (newValue.length > 2) {
        this.getList(newValue);
      }
    }

    getList(searchStr: string) {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.cancelSource = axios.CancelToken.source();
      this.ui.spinner.show();
      return ApiService.get(
        "entries",
        {
          skip: 0,
          limit: 15,
          order: { name: "asc" },
          params: {
            name: searchStr,
          },
        },
        this.cancelSource
      )
        .then((response) => {
          this.cancelSource = null;
          this.total = response.data.total;
          this.searchResult = response.data.items;
          this.ui.spinner.hide();
        })
        .catch((error) => {
          this.$error(error);
        });
    }

    save() {
      this.ui.spinner.show();
      if (this.entry && this.newParent) {
        ApiService.put("entries/parent/" + this.entry._id, {
          parent: this.newParent._id,
        })
          .then((response: any) => {
            this.$success(response);
            this.ui.main.close();
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      }
    }
  }
</script>

<style scoped lang="scss">
  .entry-migrator {
    @apply flex flex-col h-full overflow-y-auto;
    .name {
      @apply pl-5  pt-5 text-lg font-medium;
    }
    .placeholder {
      @apply pl-5 pt-2 text-gray-400;
      &.main {
        @apply pt-5 text-center;
      }
    }
    .search-cntnr {
      @apply flex-1 pt-5 pb-10;
      .placeholder {
        @apply pt-5 text-center;
      }
      .i-cntnr {
        @apply relative;
        input {
        }
        .ion {
          @apply absolute right-7 top-2/4;
          transform: translateY(-50%);
        }
      }
      ul {
        li {
          @apply mx-5 mt-3;
        }
      }
    }
    .save-cta {
      @apply w-full py-3 px-6 uppercase tracking-widest font-medium  text-center;
      background-color: $disabled_bg;
      color: $disabled_font;
      &.enabled {
        @apply text-white;
        background-color: $secondary;
      }
    }
  }
</style>
