
  import { Options, Vue } from "vue-class-component";

  import { useUI } from "@/state/uiStore";

  @Options({
    name: "ConfirmPopup",
    components: {},
    props: {},
  })
  export default class ConfirmPopup extends Vue {
    ui: any = null;

    async created() {
      this.ui = useUI();
    }

    ko() {
      if (this.ui.state.confirm.koCb) {
        this.ui.state.confirm.koCb();
      }
      this.ui.confirm.hide();
    }

    ok() {
      this.ui.state.confirm.okCb();
      this.ui.confirm.hide();
    }
  }
