<template>
  <div class="textarea i-cntnr" :class="getCntnrClass">
    <div v-if="!open" @click="enable" class="open">
      <CreateIcon /> <span>Ajouter un texte</span>
    </div>
    <CloseCircleIcon
      v-if="open && !isFullScreen"
      class="close"
      @click="disable"
      w="16"
      h="16"
    />
    <ExpandIcon v-if="open" @click="toggleFullScreen" class="full" w="16" h="16" />
    <textarea
      v-if="open"
      :value="modelValue"
      :placeholder="placeholder"
      @keydown="$emit('update:modelValue', $event.target.value)"
    >
    </textarea>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import ExpandIcon from "vue-ionicons/dist/md-expand.vue";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";
  import CreateIcon from "vue-ionicons/dist/md-create.vue";

  @Options({
    name: "Textarea",
    components: { CloseCircleIcon, CreateIcon, ExpandIcon },
    props: {
      modelValue: String,
      placeholder: String,
    },
    emits: ["update:modelValue"],
  })
  export default class Textarea extends Vue {
    modelValue!: string;
    placeholder!: string;

    isFullScreen: boolean = false;
    open: boolean = false;

    async mounted() {
      if (this.modelValue) {
        this.open = this.modelValue.length > 0;
      }
    }

    get getCntnrClass() {
      let output = [];

      if (this.isFullScreen) {
        output.push("fullscreen");
      }
      return output.concat(" ");
    }

    enable() {
      this.open = true;
    }

    disable() {
      this.open = false;
    }

    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    }
  }
</script>

<style scoped lang="scss">
  .textarea {
    @apply relative;
    .open {
      @apply text-xs;
      .ion {
        @apply inline-block;
      }
    }
    .ion {
      &.close {
        @apply absolute top-6 right-1;
      }
      &.full {
        @apply absolute top-1 right-1;
      }
    }
    textarea {
      @apply block;
    }
    &.fullscreen {
      @apply fixed inset-0 z-40 p-0;
      .ion {
        @apply top-3 right-3;
      }
      textarea {
        @apply w-full h-full block;
      }
    }
  }
</style>
