<template>
  <div class="login-form">
    <div class="form-cntnr">
      <div class="title">Nestor</div>
      <div class="i-cntnr">
        <input type="text" v-model="email" placeholder="Email" />
      </div>
      <div class="i-cntnr">
        <input type="password" v-model="password" placeholder="Password" />
      </div>
      <div class="cta" @click="send">Entrer</div>
      <div class="error" v-if="error">Impossible de se connecter</div>
      <div class="waiting" v-if="isWaiting">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import Spinner from "@/components/Basis/Spinner.vue";
  import AuthService from "@/services/authService";
  import { useAuth } from "@/state/authStore";

  @Options({
    name: "LoginForm",
    components: { Spinner },
  })
  export default class LoginForm extends Vue {
    email: string = "";
    password: string = "";

    isWaiting: boolean = false;
    error: boolean = false;

    auth: any = null;

    async mounted() {
      this.auth = useAuth();
    }

    send() {
      this.isWaiting = true;
      AuthService.login(this.email, this.password)
        .then(() => {
          this.error = false;
          this.auth.fetch();
        })
        .catch((error: any) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.isWaiting = false;
        });
    }
  }
</script>

<style scoped lang="scss">
  .login-form {
    @apply h-screen flex justify-center items-center;
    .title {
      @apply px-5 mb-5 text-lg font-medium;
    }
    .i-cntnr {
      @apply mb-5;
    }
    .cta {
      @apply mx-5 py-3 px-6 uppercase tracking-widest font-medium text-white text-center;
      background-color: $primary;
    }
    .error {
      @apply mx-5 mt-5 py-3 px-6;
      background-color: $error;
      color: $error_alt;
    }
    .waiting {
      @apply fixed inset-0;
      @apply bg-gray-600 bg-opacity-50;
      @apply flex justify-center items-center;
    }
  }
</style>
