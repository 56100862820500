<template>
  <div class="labels-selector">
    <div
      v-if="dropdownMode"
      class="dropdown-anchor"
      @click="isMenuActive = !isMenuActive"
    >
      {{ dropdownText }}
    </div>
    <div
      class="mask"
      v-show="dropdownMode && isMenuActive"
      @click="isMenuActive = false"
    ></div>
    <transition name="fade">
      <ul v-if="labelsStore && isMenuActive">
        <li
          v-for="label in labelsStore.state.a"
          :key="label._id"
          @click="toggleSelect(label._id)"
          :class="itemClass(label)"
        >
          {{ label.name }}
        </li>
        <li @click="toggleSelect('none')">
          <strong v-if="selectedLabels.indexOf('none') !== -1">Sans label</strong>
          <span v-else>Sans label</span>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import { useLabels } from "@/state/labelsStore";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "LabelsSelector",
    components: {},
    props: {
      selectedLabels: {
        required: true,
      },
      dropdownMode: Boolean,
    },
  })
  export default class LabelsSelector extends Vue {
    dropdownMode: boolean = false;

    selectedLabels!: string[];
    labelsStore: any = null;

    isMenuActive: boolean = false;

    itemClass(label: Label) {
      let output = [];
      if (this.selectedLabels.indexOf(label._id) !== -1) {
        output.push("active");
      }
      return output.join(" ");
    }

    async mounted() {
      this.labelsStore = useLabels();
      if (!this.dropdownMode) {
        this.isMenuActive = true;
      }
    }

    get dropdownText() {
      let output = "";
      if (this.selectedLabels.length < 2) {
        output = this.selectedLabels.length + " label";
      } else {
        output = this.selectedLabels.length + " labels";
      }
      return output;
    }

    toggleSelect(labelId: string) {
      const index = this.selectedLabels.indexOf(labelId);
      if (index > -1) {
        this.selectedLabels.splice(index, 1);
      } else {
        this.selectedLabels.push(labelId);
      }
    }
  }
</script>

<style scoped lang="scss">
  .labels-selector {
    @apply relative;
    .dropdown-anchor {
    }
    .mask {
      @apply inset-0 fixed z-10;
    }
    .dropdown-anchor ~ ul {
      @apply top-full left-0 absolute p-4 z-20;
      background-color: $level_3;
      li {
        @apply text-gray-400;
        &.active {
          @apply text-white;
        }
      }
    }
  }
</style>
