<template>
  <div class="todolist-editor">
    <div class="title">Tâches</div>
    <div class="tasks-cntnr">
      <div v-if="xtra.tasks && xtra.tasks.length === 0" class="placeholder">
        Aucune tâche
      </div>
      <div v-for="task in xtra.tasks" :key="task.id" class="i-cntnr">
        <input type="text" v-model="task.name" />
        <div @click="removeTask(task.id)" class="remove-button">
          <CloseCircleIcon w="16px" h="16px" />
        </div>
      </div>
    </div>
    <div class="add-cntnr">
      <div class="add-button" @click="addTask">
        <AddIcon w="100%" h="100%" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import AddIcon from "vue-ionicons/dist/md-add.vue";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";

  @Options({
    name: "TodolistEditor",
    components: {
      AddIcon,
      CloseCircleIcon,
    },
    props: {
      xtra: {
        required: true,
      },
    },
  })
  export default class TodolistEditor extends Vue {
    xtra!: any;

    async mounted() {
      if (!this.xtra.tasks) {
        this.xtra.tasks = [];
      } else {
        let id = 0;
        for (let t of this.xtra.tasks) {
          t.id = id;
          id++;
        }
      }
    }

    addTask() {
      let id = 0;
      if (this.xtra.tasks.length > 0) {
        id = this.xtra.tasks[this.xtra.tasks.length - 1].id + 1;
      }
      this.xtra.tasks.push({
        id: id,
        name: "",
        isDone: false,
      });
    }

    removeTask(id: number) {
      const entryIndex = this.xtra.tasks.findIndex((i: any) => i.id === id);
      this.xtra.tasks.splice(entryIndex, 1);
    }
  }
</script>

<style scoped lang="scss">
  .todolist-editor {
    .title {
      @apply px-5 font-medium mb-3;
    }
    .tasks-cntnr {
      @apply pr-5;
      .placeholder {
        @apply ml-5 text-gray-400;
      }
      .i-cntnr {
        @apply relative;
        .remove-button {
          @apply absolute right-0;
          top: 50%;
          margin-top: -8px;
        }
        &:not(:last-child) {
          @apply mb-3;
        }
      }
    }
    .add-cntnr {
      @apply mt-3;
      .add-button {
        @apply rounded-full inline-block ml-5;
        bottom: #{$icon_size_2 / 2};
        right: #{$icon_size_2 / 2};
        background-color: $secondary;
        .ion {
          width: $icon_size_2;
          height: $icon_size_2;
        }
      }
    }
  }
</style>
