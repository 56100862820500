<template>
  <div class="entry-editor nestor-scrollbar">
    <div class="header">
      <span class="title">{{ opt.title }}</span>
      <span v-if="hasChange"><SaveIcon animate="beat" /></span>
    </div>
    <div v-if="parent" class="parent-cntnr">
      <div class="name">Parent :</div>
      <EntryBrief :entry="parent" />
    </div>
    <div v-if="children && children.length > 0" class="children-cntnr">
      <div class="name">Enfants :</div>
      <EntryBrief v-for="child in children" :key="child._id" :entry="child" />
    </div>
    <div class="form-cntnr">
      <LabelsSearch v-model="selectedLabels" />
      <div class="entry-data" v-for="entry in entries" :key="entry.order">
        <div v-if="entry.order" @click="removeEntry(entry.order)" class="remove-button">
          <CloseCircleIcon />
        </div>
        <div class="i-cntnr"><input v-model="entry.name" placeholder="Name" /></div>
        <Textarea v-model="entry.content" placeholder="Contenu" />
        <XtraEditor :xtra="entry.xtra" :labelIds="selectedLabels" />
      </div>
      <div class="add-cntnr" v-if="isMultiCreateEnable">
        <div class="add-button" @click="addEntry">
          <AddIcon w="100%" h="100%" />
        </div>
      </div>
    </div>
    <div :class="saveCtaClasses" @click="save">Enregistrer</div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import Textarea from "@/components/Basis/Textarea.vue";
  import EntryBrief from "@/components/Entries/EntryBrief.vue";
  import LabelsSearch from "@/components/Labels/LabelsSearch.vue";
  import XtraEditor from "@/components/Types/XtraEditor.vue";
  import AddIcon from "vue-ionicons/dist/md-add.vue";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";
  import SaveIcon from "vue-ionicons/dist/md-save.vue";

  import ApiService from "@/services/apiService";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "EntryEditor",
    components: {
      AddIcon,
      CloseCircleIcon,
      EntryBrief,
      LabelsSearch,
      SaveIcon,
      Textarea,
      XtraEditor,
    },
    props: {
      opt: {},
    },
  })
  export default class EntryEditor extends Vue {
    opt!: any;

    labelsStore: any = null;
    ui: any = null;

    mode: string = "Create";
    isMultiCreateEnable: boolean = false;

    entries: any[] = [];
    selectedLabels: string[] = [];

    parent: any = null;
    children: Entry[] = [];

    changeCtrl: string = "";

    async created() {
      this.ui = useUI();
      this.labelsStore = useLabels();

      this.mode = this.opt.mode ?? "Create";

      switch (this.mode) {
        case "Update":
          this.selectedLabels = this.opt.labels;
          this.entries = [
            {
              name: this.opt.entry.name,
              content: this.opt.entry.content,
              xtra: this.opt.entry.xtra
                ? JSON.parse(JSON.stringify(this.opt.entry.xtra))
                : {},
            },
          ];
          this.parent = this.opt.entry.parent;
          this.children = this.opt.entry.children;
          break;
        case "AddChild":
          this.prepareNewEntry();
          this.selectedLabels = this.opt.labels ?? [];
          this.parent = this.opt.entry;
          this.isMultiCreateEnable = true;
          break;
        default:
          this.prepareNewEntry();
          this.selectedLabels = this.opt.labels ?? [];
          this.isMultiCreateEnable = true;
          break;
      }

      this.changeCtrl = this.generateCtrlString(this.entries, this.selectedLabels);

      this.ui.main.setBackCtrl((backCb: any) => {
        if (this.hasChange) {
          this.ui.confirm.show(() => {
            backCb();
          });
        } else {
          backCb();
        }
      });
    }

    get canSave() {
      let out = true;
      for (const e of this.entries) {
        if (e.name.length === 0) {
          out = false;
        }
      }
      out = out && this.hasChange;

      return out;
    }

    get saveCtaClasses() {
      let out = ["save-cta"];
      if (this.canSave) {
        out.push("enabled");
      }
      return out.join(" ");
    }

    get hasChange() {
      return (
        this.changeCtrl !== this.generateCtrlString(this.entries, this.selectedLabels)
      );
    }

    generateCtrlString(entries: any, labels: any) {
      return JSON.stringify({
        e: entries,
        l: labels,
      });
    }

    prepareNewEntry() {
      let order = 0;
      if (this.entries.length > 0) {
        order = this.entries[this.entries.length - 1].order + 1;
      }
      this.entries.push({
        order: order,
        name: "",
        content: "",
        xtra: {},
      });
    }

    addEntry() {
      this.prepareNewEntry();
    }

    removeEntry(order: number) {
      const entryIndex = this.entries.findIndex((i: any) => i.order === order);
      this.entries.splice(entryIndex, 1);
    }

    save() {
      if (this.canSave) {
        if (this.mode === "Update") {
          this.saveModification();
        } else {
          this.sendEntries();
        }
      }
    }

    saveModification() {
      this.ui.spinner.show();
      const entry = this.entries[0];
      ApiService.put("entries/" + this.opt.entry._id, {
        name: entry.name,
        content: entry.content,
        labels: this.selectedLabels,
        parent: this.parent,
        children: this.children,
        xtra: entry.xtra,
      })
        .then((response: any) => {
          this.$success(response);
          this.ui.main.close();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    sendEntries() {
      this.ui.spinner.show();
      ApiService.post("entries/many", {
        labels: this.selectedLabels,
        parent: this.parent,
        entries: this.entries,
      })
        .then((response: any) => {
          this.$success(response);
          this.ui.main.close();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }
  }
</script>

<style scoped lang="scss">
  .entry-editor {
    @apply flex flex-col h-full overflow-y-auto;
    .parent-cntnr,
    .children-cntnr {
      background-color: $level_1;
      .name {
        @apply pl-5 text-lg font-medium;
      }
    }
    .form-cntnr {
      @apply flex-1 pt-5 pb-10;
      > div {
        @apply mb-5;
      }
      .entry-data {
        @apply mx-5 py-5 relative;
        background-color: $level_1;
        .remove-button {
          @apply absolute right-1 top-1;
        }
        .i-cntnr {
          &:not(:last-child) {
            @apply mb-5;
          }
        }
      }
      .add-cntnr {
        .add-button {
          @apply rounded-full inline-block ml-5;
          bottom: #{$icon_size_1 / 2};
          right: #{$icon_size_1 / 2};
          background-color: $secondary;
          .ion {
            width: $icon_size_1;
            height: $icon_size_1;
          }
        }
      }
    }
    .save-cta {
      @apply w-full py-3 px-6 uppercase tracking-widest font-medium  text-center;
      background-color: $disabled_bg;
      color: $disabled_font;
      &.enabled {
        @apply text-white;
        background-color: $secondary;
      }
    }
  }
</style>
