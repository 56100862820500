
  import { Options, Vue } from "vue-class-component";
  import LabelBrief from "@/components/Labels/LabelBrief.vue";

  import ArrowDown from "vue-ionicons/dist/md-arrow-round-down.vue";
  import ArrowUp from "vue-ionicons/dist/md-arrow-round-up.vue";
  import Calendar from "vue-ionicons/dist/md-calendar.vue";
  import CheckboxOutline from "vue-ionicons/dist/md-checkbox-outline.vue";
  import Checkmark from "vue-ionicons/dist/md-checkmark.vue";
  import CheckmarkCircle from "vue-ionicons/dist/md-checkmark-circle.vue";
  import CloseCircle from "vue-ionicons/dist/md-close-circle-outline.vue";
  import DoneAll from "vue-ionicons/dist/md-done-all.vue";
  import List from "vue-ionicons/dist/md-list.vue";
  import Search from "vue-ionicons/dist/md-search.vue";
  import SquareOutline from "vue-ionicons/dist/md-square-outline.vue";
  import XtraActions from "@/components/Types/XtraActions.vue";

  import DateHelper from "@/services/dateHelper.ts";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EntryBrief",
    components: {
      ArrowDown,
      ArrowUp,
      Calendar,
      CheckboxOutline,
      Checkmark,
      CheckmarkCircle,
      CloseCircle,
      DoneAll,
      LabelBrief,
      List,
      Search,
      SquareOutline,
      XtraActions,
    },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
      disableActions: Boolean,
    },
    emits: ["refresh", "manageSelect"],
  })
  export default class EntryBrief extends Vue {
    entry!: Entry;
    disableActions: Boolean = true;

    ui: any = null;

    isActionsOpen: boolean = false;
    isSelected: boolean = false;

    get deletedAt() {
      return this.entry.deletedAt ? DateHelper.since(this.entry.deletedAt) : "";
    }

    get updatedAt() {
      return this.entry.updatedAt ? DateHelper.since(this.entry.updatedAt) : "";
    }

    get createdAt() {
      return this.entry.createdAt ? DateHelper.since(this.entry.createdAt) : "";
    }

    get isDone() {
      return this.entry.xtra && (this.entry.xtra.completedAt || this.entry.xtra.done);
    }

    get plannedAt() {
      let output: any = false;
      if (this.entry.xtra && this.entry.xtra.plannedAt) {
        output = "= " + DateHelper.date(this.entry.xtra.plannedAt);
      }
      return output;
    }

    get after() {
      let output: any = false;
      if (this.entry.xtra && this.entry.xtra.after) {
        output = "> " + DateHelper.date(this.entry.xtra.after);
      }
      return output;
    }

    get before() {
      let output: any = false;
      if (this.entry.xtra && this.entry.xtra.before) {
        output = "< " + DateHelper.date(this.entry.xtra.before);
      }
      return output;
    }

    async created() {
      this.ui = useUI();
    }

    toggleActions() {
      if (!this.disableActions) {
        this.isActionsOpen = !this.isActionsOpen;
      }
    }

    select() {
      this.isSelected = true;
      this.$emit("manageSelect", true);
    }

    unselect() {
      this.isSelected = false;
      this.$emit("manageSelect", false);
    }

    openDetails() {
      this.ui.main.set("entryDetails", {
        data: this.entry._id,
        addCntnr: true,
      });
    }

    refresh(entry: Entry) {
      this.$emit("refresh", entry);
    }
  }
