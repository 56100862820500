
  import { Options, Vue } from "vue-class-component";

  import AddIcon from "vue-ionicons/dist/md-add.vue";

  import { useUI } from "@/state/uiStore";

  @Options({
    name: "AddCntnr",
    components: { AddIcon },
    props: {},
  })
  export default class AddCntnr extends Vue {
    ui: any = null;

    async mounted() {
      this.ui = useUI();
    }

    get isEnable() {
      return this.ui && this.ui.state && this.ui.state.main.addCntnr;
    }

    addEntry() {
      let data: any = {
        title: "Créer",
        entry: null,
        mode: "Create",
      };
      if (
        this.ui.state.main.addCntnr &&
        typeof this.ui.state.main.addCntnr === "object"
      ) {
        for (let key in this.ui.state.main.addCntnr) {
          data[key] = this.ui.state.main.addCntnr[key];
        }
      }

      this.ui.main.set("entryEditor", {
        data: data,
        addCntnr: false,
      });
    }
  }
