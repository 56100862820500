import { reactive, provide, inject, readonly } from 'vue';
import AuthService from "../services/authService";

export const createAuth = () => {
    const state = reactive({
        connected : false,
        decoded: null,
        token : null
    });

    const fetch = () => {
        const data = AuthService.init();
        if(data.decoded){            
            state.connected = new Date() < new Date(data.decoded.exp * 1000);
            state.decoded = data.decoded;
            state.token = data.token;
        }
    };
    const disconnect = () => {
        AuthService.logout();
        state.connected = false;
        state.decoded = null;
        state.token = null;
    }

    // initialize labels state
    fetch();

    return { fetch, disconnect, state: readonly(state) };
};


export const authSymbol = Symbol('auth');
export const useAuth = () => inject(authSymbol);
export const provideAuth = () => provide(authSymbol, createAuth());