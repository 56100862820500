
  import { Options, Vue } from "vue-class-component";

  import MoreIcon from "vue-ionicons/dist/md-more.vue";
  import CloseCircle from "vue-ionicons/dist/md-close-circle-outline.vue";
  import Checkmark from "vue-ionicons/dist/md-checkmark.vue";
  import DoneAll from "vue-ionicons/dist/md-done-all.vue";

  import ApiService from "@/services/apiService";
  import DateHelper from "@/services/dateHelper.ts";
  import { typesService } from "@/services/typesService";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "LabelDetails",
    components: { Checkmark, CloseCircle, DoneAll, MoreIcon },
    props: {
      label: {
        required: true,
      },
      showAction: Boolean,
    },
  })
  export default class LabelDetails extends Vue {
    label!: Label;
    showAction: Boolean = false;

    openActions: Boolean = false;

    labelsStore: any = null;
    ui: any = null;

    async mounted() {
      this.ui = useUI();
      this.labelsStore = useLabels();
    }

    get deletedAt() {
      return this.label.deletedAt ? DateHelper.since(this.label.deletedAt) : "";
    }

    get updatedAt() {
      return this.label.updatedAt ? DateHelper.since(this.label.updatedAt) : "";
    }

    get createdAt() {
      return this.label.createdAt ? DateHelper.since(this.label.createdAt) : "";
    }

    get type() {
      return typesService.strs[this.label.type];
    }

    deleteLabel() {
      this.openActions = false;
      this.ui.spinner.show();
      ApiService.delete("labels/" + this.label._id)
        .then((response) => {
          this.$success(response);
          this.labelsStore.fetch();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    hardDeleteLabel() {
      this.openActions = false;
      this.ui.confirm.show(() => {
        this.ui.spinner.show();
        ApiService.delete("labels/hard/" + this.label._id)
          .then((response) => {
            this.$warning(response);
            this.labelsStore.fetch();
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      });
    }

    restoreLabel() {
      this.openActions = false;
      this.ui.spinner.show();
      ApiService.put("labels/restore/" + this.label._id)
        .then((response) => {
          this.$success(response);
          this.labelsStore.fetch();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    openEditor() {
      this.openActions = false;
      this.ui.main.set("labelEditor", {
        data: {
          title: "Modification",
          label: this.label,
          mode: "Update",
        },
        addCntnr: false,
      });
    }
  }
