<template>
  <div class="task-editor"></div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  @Options({
    name: "TaskEditor",
    props: {
      xtra: {
        required: true,
      },
    },
  })
  export default class TaskEditor extends Vue {
    xtra!: any;

    async mounted() {}
  }
</script>

<style scoped lang="scss">
  .task-editor {
    @apply px-5;
  }
</style>
