
  import { Options, Vue } from "vue-class-component";
  import LabelDetails from "@/components/Labels/LabelDetails.vue";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  @Options({
    name: "LabelsList",
    components: { LabelDetails },
    props: {
      opt: {},
    },
  })
  export default class LabelsList extends Vue {
    opt!: any;

    labelsStore: any = null;
    ui: any = null;

    async mounted() {
      this.labelsStore = useLabels();
      this.ui = useUI();
    }

    get total() {
      let output = "?";
      if (this.labelsStore) {
        output = this.labelsStore.state.a.length;
      }
      return output;
    }

    get list() {
      let output: any[] = [];
      if (this.labelsStore && this.labelsStore.state.a.length > 0) {
        let copy = [...this.labelsStore.state.a];
        copy.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        let currentLetterObj: any = null;
        copy.forEach((e) => {
          let eFistLetter = e.name.toLowerCase()[0];
          if (currentLetterObj === null) {
            currentLetterObj = {
              letter: eFistLetter,
              labels: [e],
            };
          } else if (eFistLetter !== currentLetterObj.letter) {
            output.push(currentLetterObj);
            currentLetterObj = {
              letter: eFistLetter,
              labels: [e],
            };
          } else {
            currentLetterObj.labels.push(e);
          }
        });
        output.push(currentLetterObj);
      }
      return output;
    }

    addLabel() {
      this.ui.main.set("labelEditor", {
        data: {
          title: "Création",
          label: null,
          mode: "Create",
        },
        addCntnr: false,
      });
    }
  }
