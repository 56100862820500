import { reactive, provide, inject, readonly } from 'vue';
import ApiService from "../services/apiService";
import { typesService } from "../services/typesService";

export const createLabels = () => {
    const state = reactive({
        a: [], // Just the list 
        i: {}, // Indexed by label ids
        // s: {}, // Indexed by label slugs - NOT USED, slugs arent available yet
    });

    const fetch = (cb) => {
        ApiService.get("labels", { withDeleted: true })
            .then((response) => {
                state.a = response.data;
                state.i = {};
                Object.keys(typesService.strs).forEach((e) => {
                    state[e] = [];
                });
                response.data.forEach(element => {
                    state.i[element._id] = element;
                    state[element.type].push(element);
                });
                if (cb) {
                    cb();
                }
            })
            .catch((error) => {
                console.log(error);
                alert(error.response.message);
            })
    };

    return { fetch, state: readonly(state) };
};


export const labelsSymbol = Symbol('labels');
export const useLabels = () => inject(labelsSymbol);
export const provideLabels = () => provide(labelsSymbol, createLabels());