
  import { Options, Vue } from "vue-class-component";
  import EntryBrief from "@/components/Entries/EntryBrief.vue";

  import ApiService from "@/services/apiService";
  import DateHelper from "@/services/dateHelper.ts";

  import { useLabels } from "@/state/labelsStore";
  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "EntriesAgenda",
    components: { EntryBrief },
    props: {
      opt: {},
    },
  })
  export default class EntriesAgenda extends Vue {
    opt!: any;

    entries: Entry[] = [];
    workable: any = {};

    groups: any = [];
    groupsOrder: any = ["past", "today", "days", "after", "unplanned"];

    isLoading: boolean = true;
    isTreatedHidden: boolean = true;

    ui: any = null;
    labelsStore: any = null;

    async mounted() {
      this.labelsStore = useLabels();
      this.ui = useUI();

      this.getList();
    }

    computeGroups() {
      this.workable = {
        past: [],
        today: [],
        days: {
          // 2021_05_09: [],
        },
        after: [],
        unplanned: [],
      };
      this.groups = [];

      if (this.entries.length > 0) {
        for (const e of this.entries) {
          this.treatEntry(e);
        }

        for (const key of this.groupsOrder) {
          this.treatGroup(key);
        }
      }
    }

    treatEntry(e: Entry) {
      if (this.isTreatedHidden && e.xtra && e.xtra.completedAt) {
        return;
      }
      let isSet = false;
      if (e.xtra) {
        if (e.xtra.plannedAt) {
          this.qualify(e.xtra.plannedAt, e);
          isSet = true;
        } else if (e.xtra.after) {
          this.qualify(e.xtra.after, e);
          isSet = true;
        } else if (e.xtra.before) {
          this.qualify(e.xtra.before, e);
          isSet = true;
        }
      }
      if (!isSet) {
        this.workable.unplanned.push({
          e: e,
          date: null,
        });
      }
    }

    treatGroup(key: string) {
      if (Array.isArray(this.workable[key])) {
        if (this.workable[key].length > 0) {
          this.workable[key].sort(this.compare);
          this.groups.push({
            name: DateHelper.locale[key],
            entries: this.workable[key],
          });
        }
      } else {
        let subKeys = Object.keys(this.workable[key]);
        subKeys.sort();
        for (const date of subKeys) {
          this.workable[key][date].entries.sort(this.compare);
          this.groups.push({
            name: this.workable[key][date].name,
            entries: this.workable[key][date].entries,
          });
        }
      }
    }

    qualify(date: string, e: Entry) {
      let infos = DateHelper.qualify(date);
      let obj = {
        e: e,
        date: new Date(date),
      };

      if (this.workable[infos.q]) {
        this.workable[infos.q].push(obj);
      } else {
        if (!this.workable.days[infos.slug]) {
          this.workable.days[infos.slug] = {
            name: infos.locale + " " + infos.date,
            entries: [],
          };
        }
        this.workable.days[infos.slug].entries.push(obj);
      }
    }

    getList() {
      this.isLoading = true;
      this.ui.spinner.show();
      let plannables = this.labelsStore.state.event.map((a: Label) => a._id);
      return ApiService.get("entries", {
        order: { name: "asc" },
        params: {
          labelIds: plannables,
        },
      })
        .then((response) => {
          this.entries = response.data.items;
          this.computeGroups();
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.ui.spinner.hide();
        });
    }

    hideTreated() {
      this.isTreatedHidden = true;
      this.computeGroups();
    }

    showTreated() {
      this.isTreatedHidden = false;
      this.computeGroups();
    }

    compare(a: any, b: any) {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    }
  }
