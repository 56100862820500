<template>
  <div class="event-actions">
    <div v-if="isComplete" @click="removeMark">
      <span v-if="mode === 'text'">Non Fait</span>
      <RemoveCircleIcon v-if="mode === 'icon'" w="20" h="20" />
    </div>
    <div v-else @click="mark">
      <span v-if="mode === 'text'">Fait</span>
      <CheckmarkCircleIcon v-if="mode === 'icon'" w="20" h="20" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import CheckmarkCircleIcon from "vue-ionicons/dist/md-checkmark-circle.vue";
  import RemoveCircleIcon from "vue-ionicons/dist/md-remove-circle.vue";

  import EntriesHelper from "@/services/entriesHelper";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EventActions",
    components: {
      CheckmarkCircleIcon,
      RemoveCircleIcon,
    },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
      mode: String,
    },
    emits: ["refresh"],
  })
  export default class EventActions extends Vue {
    entry!: Entry;
    ui: any = null;
    mode: string = "icon"; // text

    async mounted() {
      this.ui = useUI();
    }
    get isComplete() {
      let output = false;
      if (this.entry.xtra && this.entry.xtra.completedAt) {
        output = this.entry.xtra.completedAt;
      }
      return output;
    }

    removeMark() {
      this.ui.spinner.show();
      EntriesHelper.updateEntriesXtra(this.entry, {
        completedAt: null,
      })
        .then((response: any) => {
          this.$success(response);
          this.$emit("refresh", response.data.data);
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }

    mark() {
      this.ui.spinner.show();
      EntriesHelper.updateEntriesXtra(this.entry, {
        completedAt: new Date(),
      })
        .then((response: any) => {
          this.$success(response);
          this.$emit("refresh", response.data.data);
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.ui.spinner.hide();
        });
    }
  }
</script>

<style scoped lang="scss">
  .event-actions {
  }
</style>
