import jwt_decode from "jwt-decode";
import axios from 'axios';

export default abstract class AuthService {
    static authStorageKey: string = "NestorAuthKey";

    static login(email: string, password: string): Promise<any> {
        return axios.post(process.env.VUE_APP_API_URL + "/auth/login", {
            email: email,
            password: password,
        }).then((response: any) => {
            const accessToken: string = response.data.access_token;
            // Throw error if invalid
            const decoded = jwt_decode(accessToken);
            window.localStorage.setItem(this.authStorageKey, accessToken);
        });
    }

    static logout(){
        window.localStorage.removeItem(this.authStorageKey);
    }

    static init(){
        let decoded = null;
        const stored = window.localStorage.getItem(this.authStorageKey);
        if (stored) {
            try {
                decoded = jwt_decode(stored);
            } catch (error) {
                console.log(error)
            }
        }
        return {
            decoded : decoded,
            token : stored
        };
    }
}