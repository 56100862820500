<template>
  <div class="todolist-details">
    <div><span class="is-done">Traité:</span> {{ done }}</div>
    <div class="tasks-cntnr">
      <draggable v-model="entry.xtra.tasks" group="tasks" item-key="id">
        <template #item="{ element }">
          <div class="task">
            <span v-if="element.isDone" class="mark done"></span>
            <span v-else class="mark todo"></span>
            <span class="name">{{ element.name }}</span>
            <span
              v-if="element.isDone"
              @click="element.isDone = false"
              @touchstart="element.isDone = false"
              class="action"
            >
              <RemoveCircleIcon w="20" h="20" />
            </span>
            <span
              v-else
              @click="element.isDone = true"
              @touchstart="element.isDone = true"
              class="action"
            >
              <CheckmarkCircleIcon w="20" h="20" />
            </span>
          </div>
        </template>
      </draggable>
      <div v-if="entry.xtra.tasks && entry.xtra.tasks.length === 0" class="placeholder">
        Aucune tâche
      </div>
      <div v-else class="save-cntnr">
        <div :class="saveCtaClasses" @click="saveChange">Enregistrer</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import CheckmarkCircleIcon from "vue-ionicons/dist/md-checkmark-circle.vue";
  import draggable from "vuedraggable";
  import RemoveCircleIcon from "vue-ionicons/dist/md-remove-circle.vue";

  import EntriesHelper from "@/services/entriesHelper";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "TodolistDetails",
    components: {
      CheckmarkCircleIcon,
      draggable,
      RemoveCircleIcon,
    },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
    },
    emits: ["refresh"],
  })
  export default class TodolistDetails extends Vue {
    entry!: Entry;

    ui: any = null;
    updateCtrl: string = "";

    async mounted() {
      this.ui = useUI();
      this.setCtrl(this.entry);
    }

    get done() {
      let output = "Non";
      if (this.entry.xtra && this.entry.xtra.done) {
        output = this.entry.xtra.done ? "Oui" : "Non";
      }
      return output;
    }

    get hasChange() {
      let current = JSON.stringify(this.entry.xtra.tasks);
      return current !== this.updateCtrl;
    }

    get saveCtaClasses() {
      let out = ["save-cta"];
      if (this.hasChange) {
        out.push("enabled");
      }
      return out.join(" ");
    }

    setCtrl(entry: Entry) {
      this.updateCtrl = JSON.stringify(entry.xtra.tasks);
    }

    saveChange() {
      if (this.hasChange) {
        this.ui.spinner.show();
        EntriesHelper.updateEntriesXtra(this.entry, {
          tasks: this.entry.xtra.tasks,
        })
          .then((response: any) => {
            this.$success(response);
            this.setCtrl(response.data.data);
            this.$emit("refresh", response.data.data);
          })
          .catch((error) => {
            this.$error(error);
          })
          .finally(() => {
            this.ui.spinner.hide();
          });
      }
    }
  }
</script>

<style scoped lang="scss">
  .todolist-details {
    .is-done {
      @apply font-medium;
    }
    .tasks-cntnr {
      @apply pl-1 pr-6 mt-5;
      .task {
        @apply flex items-center;
        &:not(:last-child) {
          @apply mb-3;
        }
        .mark {
          @apply rounded-full block mr-2;
          width: 1em;
          height: 1em;
          &.todo {
            background: $warning-alt;
          }
          &.done {
            background: $success-alt;
          }
        }
        .name {
          @apply flex-1;
        }
      }
      .placeholder {
        @apply text-gray-400;
      }
      .save-cntnr {
        @apply flex justify-end mt-3;
        .save-cta {
          @apply py-2 px-4 uppercase tracking-widest font-medium text-sm;
          background-color: $disabled_bg;
          color: $disabled_font;
          &.enabled {
            @apply text-white;
            background-color: $secondary;
          }
        }
      }
    }
  }
</style>
