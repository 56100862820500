
  import { Options, Vue } from "vue-class-component";
  import { useLabels } from "@/state/labelsStore";
  // eslint-disable-next-line no-unused-vars
  import Label from "@/interfaces/label.interface";

  @Options({
    name: "LabelsSelector",
    components: {},
    props: {
      selectedLabels: {
        required: true,
      },
      dropdownMode: Boolean,
    },
  })
  export default class LabelsSelector extends Vue {
    dropdownMode: boolean = false;

    selectedLabels!: string[];
    labelsStore: any = null;

    isMenuActive: boolean = false;

    itemClass(label: Label) {
      let output = [];
      if (this.selectedLabels.indexOf(label._id) !== -1) {
        output.push("active");
      }
      return output.join(" ");
    }

    async mounted() {
      this.labelsStore = useLabels();
      if (!this.dropdownMode) {
        this.isMenuActive = true;
      }
    }

    get dropdownText() {
      let output = "";
      if (this.selectedLabels.length < 2) {
        output = this.selectedLabels.length + " label";
      } else {
        output = this.selectedLabels.length + " labels";
      }
      return output;
    }

    toggleSelect(labelId: string) {
      const index = this.selectedLabels.indexOf(labelId);
      if (index > -1) {
        this.selectedLabels.splice(index, 1);
      } else {
        this.selectedLabels.push(labelId);
      }
    }
  }
