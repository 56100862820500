
  import { Options, Vue } from "vue-class-component";
  import AddIcon from "vue-ionicons/dist/md-add.vue";
  import CloseCircleIcon from "vue-ionicons/dist/md-close-circle.vue";

  @Options({
    name: "TodolistEditor",
    components: {
      AddIcon,
      CloseCircleIcon,
    },
    props: {
      xtra: {
        required: true,
      },
    },
  })
  export default class TodolistEditor extends Vue {
    xtra!: any;

    async mounted() {
      if (!this.xtra.tasks) {
        this.xtra.tasks = [];
      } else {
        let id = 0;
        for (let t of this.xtra.tasks) {
          t.id = id;
          id++;
        }
      }
    }

    addTask() {
      let id = 0;
      if (this.xtra.tasks.length > 0) {
        id = this.xtra.tasks[this.xtra.tasks.length - 1].id + 1;
      }
      this.xtra.tasks.push({
        id: id,
        name: "",
        isDone: false,
      });
    }

    removeTask(id: number) {
      const entryIndex = this.xtra.tasks.findIndex((i: any) => i.id === id);
      this.xtra.tasks.splice(entryIndex, 1);
    }
  }
