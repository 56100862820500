
  import { Options, Vue } from "vue-class-component";

  import AddCntnr from "@/components/Layout/AddCntnr.vue";
  import ConfirmPopup from "@/components/Basis/ConfirmPopup.vue";
  import EntriesAgenda from "@/components/Entries/EntriesAgenda.vue";
  import EntriesSearch from "@/components/Entries/EntriesSearch.vue";
  import EntryDetails from "@/components/Entries/EntryDetails.vue";
  import EntryEditor from "@/components/Entries/EntryEditor.vue";
  import EntryMigrator from "@/components/Entries/EntryMigrator.vue";
  import LabelEditor from "@/components/Labels/LabelEditor.vue";
  import LabelsList from "@/components/Labels/LabelsList.vue";
  import Menu from "@/components/Layout/Menu.vue";
  import Spinner from "@/components/Basis/Spinner.vue";

  import { useUI } from "@/state/uiStore";
  import { useLabels } from "@/state/labelsStore";

  @Options({
    name: "Nestor",
    components: {
      AddCntnr,
      ConfirmPopup,
      EntriesAgenda,
      EntriesSearch,
      EntryDetails,
      EntryEditor,
      EntryMigrator,
      LabelEditor,
      LabelsList,
      Menu,
      Spinner,
    },
    props: {},
  })
  export default class Nestor extends Vue {
    mainOpt: any = null;
    ui: any = null;
    labels: any = null;

    async created() {
      this.ui = useUI();
      this.labels = useLabels();
      this.labels.fetch();
    }

    async mounted() {
      this.ui.main.set(
        "initial",
        {
          origin: null,
          data: null,
          addCntnr: true,
        },
        true
      );
    }
  }
