<template>
  <div class="entry-brief">
    <div @click="toggleActions">
      <div class="top">
        <div class="labels">
          <LabelBrief v-for="lId in entry.labels" :key="lId" :label-id="lId" />
        </div>
        <div class="date">
          <div v-if="entry.deletedAt" class="deleted">
            <span>{{ deletedAt }}</span>
            <CloseCircle w="15" h="15" />
          </div>
          <div v-else-if="entry.updatedAt">
            <span> {{ updatedAt }}</span>
            <DoneAll w="15" h="15" />
          </div>
          <div v-else>
            <span>{{ createdAt }}</span>
            <Checkmark w="15" h="15" />
          </div>
        </div>
      </div>
      <div class="main">
        {{ entry.name }}
      </div>
      <div class="bot">
        <span v-if="isDone">
          <CheckmarkCircle w="15" h="15" class="primary-color" />
        </span>
        <span v-if="entry.content">
          <List w="15" h="15" />
        </span>
        <span v-if="entry.parent">
          <ArrowUp w="15" h="15" />
        </span>
        <span v-if="entry.children.length > 0">
          <ArrowDown w="15" h="15" /> <span>{{ entry.children.length }}</span>
        </span>
        <span v-if="after">
          <Calendar w="15" h="15" /> <span>{{ after }}</span>
        </span>
        <span v-if="plannedAt">
          <Calendar w="15" h="15" /> <span>{{ plannedAt }}</span>
        </span>
        <span v-if="before">
          <Calendar w="15" h="15" /> <span>{{ before }}</span>
        </span>
      </div>
    </div>
    <div v-if="isActionsOpen" class="actions">
      <Search @click="openDetails" w="20" h="20" class="details" />
      <XtraActions :entry="entry" @refresh="refresh" />
      <CheckboxOutline v-if="isSelected" @click="unselect" w="20" h="20" />
      <SquareOutline v-else @click="select" w="20" h="20" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import LabelBrief from "@/components/Labels/LabelBrief.vue";

  import ArrowDown from "vue-ionicons/dist/md-arrow-round-down.vue";
  import ArrowUp from "vue-ionicons/dist/md-arrow-round-up.vue";
  import Calendar from "vue-ionicons/dist/md-calendar.vue";
  import CheckboxOutline from "vue-ionicons/dist/md-checkbox-outline.vue";
  import Checkmark from "vue-ionicons/dist/md-checkmark.vue";
  import CheckmarkCircle from "vue-ionicons/dist/md-checkmark-circle.vue";
  import CloseCircle from "vue-ionicons/dist/md-close-circle-outline.vue";
  import DoneAll from "vue-ionicons/dist/md-done-all.vue";
  import List from "vue-ionicons/dist/md-list.vue";
  import Search from "vue-ionicons/dist/md-search.vue";
  import SquareOutline from "vue-ionicons/dist/md-square-outline.vue";
  import XtraActions from "@/components/Types/XtraActions.vue";

  import DateHelper from "@/services/dateHelper.ts";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EntryBrief",
    components: {
      ArrowDown,
      ArrowUp,
      Calendar,
      CheckboxOutline,
      Checkmark,
      CheckmarkCircle,
      CloseCircle,
      DoneAll,
      LabelBrief,
      List,
      Search,
      SquareOutline,
      XtraActions,
    },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
      disableActions: Boolean,
    },
    emits: ["refresh", "manageSelect"],
  })
  export default class EntryBrief extends Vue {
    entry!: Entry;
    disableActions: Boolean = true;

    ui: any = null;

    isActionsOpen: boolean = false;
    isSelected: boolean = false;

    get deletedAt() {
      return this.entry.deletedAt ? DateHelper.since(this.entry.deletedAt) : "";
    }

    get updatedAt() {
      return this.entry.updatedAt ? DateHelper.since(this.entry.updatedAt) : "";
    }

    get createdAt() {
      return this.entry.createdAt ? DateHelper.since(this.entry.createdAt) : "";
    }

    get isDone() {
      return this.entry.xtra && (this.entry.xtra.completedAt || this.entry.xtra.done);
    }

    get plannedAt() {
      let output: any = false;
      if (this.entry.xtra && this.entry.xtra.plannedAt) {
        output = "= " + DateHelper.date(this.entry.xtra.plannedAt);
      }
      return output;
    }

    get after() {
      let output: any = false;
      if (this.entry.xtra && this.entry.xtra.after) {
        output = "> " + DateHelper.date(this.entry.xtra.after);
      }
      return output;
    }

    get before() {
      let output: any = false;
      if (this.entry.xtra && this.entry.xtra.before) {
        output = "< " + DateHelper.date(this.entry.xtra.before);
      }
      return output;
    }

    async created() {
      this.ui = useUI();
    }

    toggleActions() {
      if (!this.disableActions) {
        this.isActionsOpen = !this.isActionsOpen;
      }
    }

    select() {
      this.isSelected = true;
      this.$emit("manageSelect", true);
    }

    unselect() {
      this.isSelected = false;
      this.$emit("manageSelect", false);
    }

    openDetails() {
      this.ui.main.set("entryDetails", {
        data: this.entry._id,
        addCntnr: true,
      });
    }

    refresh(entry: Entry) {
      this.$emit("refresh", entry);
    }
  }
</script>

<style scoped lang="scss">
  .entry-brief {
    @apply px-4 pt-2 w-full;
    background-color: $level_2;
    .top {
      @apply flex text-xs;
      .labels {
        @apply flex flex-1;
        & > *:not(:last-child) {
          @apply mr-1;
        }
      }
      .date {
        .deleted {
          color: $error_alt;
        }
        > div {
          @apply flex justify-end items-center;
          > span {
            @apply block mr-2;
          }
        }
      }
    }
    .main {
      @apply w-full font-medium truncate;
    }
    .bot {
      @apply pb-2 flex text-gray-400;
      > span {
        @apply flex;
        &:not(:last-child) {
          @apply mr-2;
        }
        > span {
          @apply ml-1 text-xs;
        }
      }
    }
    .actions {
      @apply py-1 flex;
      background-color: $level_3;
      .details {
        @apply mr-2;
      }
      .xtra-actions {
        @apply flex-1;
      }
    }
  }
</style>
