import { App } from "vue";
import { Vue } from "vue-class-component";


export type Params = {
    type: string;
    message: string;
}

export type Toast = {
    (response: any): void
}

class Builder {
    delay: number = 5000;
    cntnr: HTMLDivElement;

    toasts: any[] = [];

    constructor() {
        this.cntnr = document.createElement('div');
        this.cntnr.className += 'toaster-cntnr';

        const body = document.querySelector('body');
        if (body) {
            body.appendChild(this.cntnr);
        }
    }

    toast(params: Params) {
        const toast = this.generateToast(params);

        this.computeHeights();

        toast.onclick = () => {
            this.close(toast);
        }

        setTimeout(() => {
            this.close(toast);
        }, this.delay);
    }

    close(toast: HTMLDivElement) {
        toast.style.bottom = '100%';
        toast.style.opacity = '.5';
        setTimeout(() => {
            toast.remove();
            const toastIndex = this.toasts.findIndex((t) => (t.id === toast.id));
            this.toasts.splice(toastIndex, 1);
            this.computeHeights();
        }, 400);
    }

    computeHeights() {
        const margin = 15;
        let sum = 10;
        for (let i = this.toasts.length - 1; i >= 0; i--) {
            const toast = this.toasts[i];
            sum += toast.offsetHeight + margin;
            let mt = -1 * sum;
            toast.style.bottom = mt + 'px';
        }
    }

    private generateToast(params: Params): HTMLDivElement {
        const toast: HTMLDivElement = document.createElement('div');
        toast.className = ['toast', params.type].join(' ');
        toast.id = 'toast-' + (new Date()).getTime();

        toast.appendChild(document.createTextNode(params.message));

        toast.style.bottom = '100%';
        toast.style.zIndex = '' + this.toasts.length;

        this.cntnr.prepend(toast);
        this.toasts.push(toast);
        return toast;
    }
}
const builder = new Builder();


export default {
    install: (app: App) => {
        const success = function (response: any) {
            builder.toast({
                type: 'success',
                message: response.data.message
            });
        }
        const error = function (err: any) {
            if (err && err.response) {
                builder.toast({
                    type: 'error',
                    message: err.response.data.message
                });
            } else {
                console.log(err)
            }
        }

        const warning = function (response: any) {
            builder.toast({
                type: 'warning',
                message: response.data.message
            });
        }

        app.config.globalProperties.$success = success;
        app.config.globalProperties.$error = error;
        app.config.globalProperties.$warning = warning;
    }
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $success: Toast;
        $error: Toast;
        $warning: Toast;
    }
}
