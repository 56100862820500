<template>
  <div class="entry-details">
    <div v-if="!isLoading">
      <div class="header">
        <div class="title">
          {{ entry.name }}
        </div>
        <div class="actions">
          <MoreIcon @click="openActions = !openActions" />
          <div class="mask" v-if="openActions" @click="openActions = false"></div>
          <div class="actions-cntnr" v-if="openActions">
            <EntryActions
              :entry="entry"
              @refresh="refresh"
              @closeParent="openActions = false"
            />
            <XtraActions :entry="entry" @refresh="refresh" mode="text" />
          </div>
        </div>
      </div>
      <div v-if="entry.deletedAt" class="deleted">
        <CloseCircle w="19" h="19" />
        <div>{{ deletedAt }}</div>
        <div>({{ deletedSince }})</div>
      </div>
      <div class="main">
        <div class="labels-cntnr">
          <div v-if="entry.labels.length > 0" class="labels-wrapper">
            <LabelBrief v-for="lId in entry.labels" :key="lId" :label-id="lId" />
          </div>
          <div v-else class="label-placeholder">Sans label</div>
        </div>
        <div v-if="entry.content" class="content">
          {{ entry.content }}
        </div>
        <XtraDetails :entry="entry" @refresh="refresh" />
        <div class="date">
          <div>
            <Checkmark w="19" h="19" />
            <div>{{ createdAt }}</div>
            <div>({{ createdSince }})</div>
          </div>
          <div v-if="entry.updatedAt">
            <DoneAll w="19" h="19" />
            <div>{{ updatedAt }}</div>
            <div>({{ updatedSince }})</div>
          </div>
        </div>
      </div>
      <div v-if="entry.parent" class="parent-cntnr">
        <div class="name">Parent :</div>
        <EntryBrief :entry="entry.parent" />
      </div>
      <div class="children-cntnr" v-if="entry.children.length > 0">
        <div class="name">Enfants :</div>
        <EntriesList :entries="entry.children" :total="entry.children.length" />
      </div>
      <div v-if="entry.xtra" class="xtra-raw">
        <p @click="showXtraRaw = !showXtraRaw">Voir XTRA RAW</p>
        <p v-if="showXtraRaw">{{ entry.xtra }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { watchEffect } from "vue";
  import { Options, Vue } from "vue-class-component";

  import EntryBrief from "@/components/Entries/EntryBrief.vue";
  import EntriesList from "@/components/Entries/EntriesList.vue";
  import LabelBrief from "@/components/Labels/LabelBrief.vue";
  import XtraDetails from "@/components/Types/XtraDetails.vue";
  import XtraActions from "@/components/Types/XtraActions.vue";
  import EntryActions from "@/components/Entries/EntryActions.vue";

  import CloseCircle from "vue-ionicons/dist/md-close-circle-outline.vue";
  import Checkmark from "vue-ionicons/dist/md-checkmark.vue";
  import DoneAll from "vue-ionicons/dist/md-done-all.vue";
  import MoreIcon from "vue-ionicons/dist/md-more.vue";

  import ApiService from "@/services/apiService";
  import DateHelper from "@/services/dateHelper.ts";

  import { useUI } from "@/state/uiStore";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "EntryDetails",
    components: {
      Checkmark,
      CloseCircle,
      DoneAll,
      EntryActions,
      EntryBrief,
      EntriesList,
      LabelBrief,
      MoreIcon,
      XtraDetails,
      XtraActions,
    },
    props: {
      entryId: {
        required: true,
        type: String,
      },
    },
  })
  export default class EntryDetails extends Vue {
    entryId!: string;

    entry: Entry | null = null;
    isLoading: Boolean = true;
    showXtraRaw: Boolean = false;
    openActions: Boolean = false;

    ui: any = null;

    get deletedAt() {
      return this.entry && this.entry.deletedAt
        ? DateHelper.date(this.entry.deletedAt)
        : "";
    }

    get deletedSince() {
      return this.entry && this.entry.deletedAt
        ? DateHelper.since(this.entry.deletedAt)
        : "";
    }

    get updatedAt() {
      return this.entry && this.entry.updatedAt
        ? DateHelper.date(this.entry.updatedAt)
        : "";
    }

    get updatedSince() {
      return this.entry && this.entry.updatedAt
        ? DateHelper.since(this.entry.updatedAt)
        : "";
    }

    get createdAt() {
      return this.entry && this.entry.createdAt
        ? DateHelper.date(this.entry.createdAt)
        : "";
    }

    get createdSince() {
      return this.entry && this.entry.createdAt
        ? DateHelper.since(this.entry.createdAt)
        : "";
    }

    async created() {
      this.ui = useUI();
      watchEffect(() => this.loadEntry());
    }

    refresh(refreshed: Entry) {
      this.openActions = false;
      this.entry = refreshed;
    }

    loadEntry() {
      this.isLoading = true;
      this.ui.spinner.show();
      ApiService.get("entries/" + this.entryId)
        .then((response: any) => {
          this.entry = response.data;
        })
        .catch((error) => {
          this.$error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.ui.spinner.hide();
        });
    }
  }
</script>

<style scoped lang="scss">
  .entry-details {
    .actions {
      @apply absolute top-3 right-3;
      .ion {
      }
      .mask {
        @apply inset-0 fixed z-10;
      }
      .actions-cntnr {
        @apply absolute top-full right-0 z-10;
        background-color: $level_3;
      }
    }
    .deleted {
      @apply px-5 py-3;
      @apply flex items-center;
      background-color: $error;
      color: $error_alt;
      > div {
        @apply text-sm block;
        &:not(:last-child) {
          @apply mr-2;
        }
      }
    }
    .parent-cntnr,
    .children-cntnr {
      @apply pt-5;
      .name {
        @apply pl-5 text-lg font-medium;
      }
      ul {
        li {
          &:not(:last-child) {
            @apply mb-2;
          }
        }
      }
    }
    .main {
      @apply px-5 pt-5;
      .labels-cntnr {
        @apply mb-5;
        .labels-wrapper {
          @apply flex;
          > div {
            @apply mr-2;
          }
        }
        .label-placeholder {
          color: $secondary;
        }
      }
      .content {
        @apply mb-5 p-2 break-words whitespace-pre-line;
        background-color: $level_1;
      }

      .date {
        @apply mt-5;
        > div {
          @apply flex items-center;
          > div {
            @apply text-sm block;
            &:not(:last-child) {
              @apply mr-2;
            }
          }
        }
      }
    }
    .xtra-raw {
      @apply px-5 mt-5 text-gray-600;
    }
  }
</style>
