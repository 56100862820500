
  import { Options, Vue } from "vue-class-component";
  import DatetimePicker from "@/components/Basis/DatetimePicker.vue";

  @Options({
    name: "EventEditor",
    components: { DatetimePicker },
    props: {
      xtra: {
        required: true,
      },
    },
  })
  export default class EventEditor extends Vue {
    xtra!: any;
  }
