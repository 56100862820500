<template>
  <div class="xtra-details">
    <div v-if="isTask">
      <TaskDetails :entry="entry" />
    </div>
    <div v-if="isEvent">
      <EventDetails :entry="entry" />
    </div>
    <div v-if="isTodolist">
      <TodolistDetails :entry="entry" @refresh="refresh" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";

  import { typesService } from "@/services/typesService";

  import EventDetails from "@/components/Types/EventDetails.vue";
  import TaskDetails from "@/components/Types/TaskDetails.vue";
  import TodolistDetails from "@/components/Types/TodolistDetails.vue";

  // eslint-disable-next-line no-unused-vars
  import Entry from "@/interfaces/entry.interface";

  @Options({
    name: "XtraDetails",
    components: { EventDetails, TaskDetails, TodolistDetails },
    props: {
      entry: {
        required: true,
        type: Object as () => Entry,
      },
    },
    emits: ["refresh"],
  })
  export default class XtraDetails extends Vue {
    entry!: Entry;

    types: string[] = [];

    async mounted() {
      this.types = typesService.getEntryTypes(this.entry);
    }

    get isEvent() {
      return this.types.indexOf(typesService.vals.Event) !== -1;
    }

    get isTask() {
      return this.types.indexOf(typesService.vals.Task) !== -1;
    }

    get isTodolist(): boolean {
      return this.types.indexOf(typesService.vals.Todolist) !== -1;
    }

    refresh(entry: Entry) {
      this.$emit("refresh", entry);
    }
  }
</script>

<style scoped></style>
