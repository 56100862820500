
  import { Options, Vue } from "vue-class-component";

  @Options({
    name: "TaskEditor",
    props: {
      xtra: {
        required: true,
      },
    },
  })
  export default class TaskEditor extends Vue {
    xtra!: any;

    async mounted() {}
  }
