import { createApp } from 'vue'
import App from './App.vue'

import { labelsSymbol, createLabels } from './state/labelsStore';
import { uiSymbol, createUIStore } from './state/uiStore';
import { authSymbol, createAuth } from './state/authStore';

import Toaster from "./plugins/toaster";
import VCalendar from 'v-calendar';

require('vue-ionicons/ionicons.css')
require("@vueform/multiselect/themes/default.css");
import './index.scss';

createApp(App)
    .provide(labelsSymbol, createLabels())
    .provide(uiSymbol, createUIStore())
    .provide(authSymbol, createAuth())
    .use(Toaster)
    .use(VCalendar, {})
    .mount('#app');